import React, { useEffect, useState } from 'react';
import { ASSESSMENT_STATE } from '../../../services/constants';
import { X, ChevronDown } from 'react-feather';

const FilterMenus = ({ filters, setFilters }) => {

    const [showSkillFilter, setShowSkillFilter] = useState(false);
    const [showJobFilter, setShowJobFilter] = useState(false);

    const getFilterLabel = filter => {
        switch (filter) {
            case ASSESSMENT_STATE.completed:
                return 'Completed';
            case ASSESSMENT_STATE.in_progress:
                return 'In Progress';
            case ASSESSMENT_STATE.not_started:
                return 'Not started';
            default:
                return 'All';
        }
    }

    useEffect(() => {
        setShowSkillFilter(false);
        setShowJobFilter(false);
    }, [filters]);

    return (
        <>
            <div className='filterOpts'>
                <p>Skills Test</p>
                {filters.state !== 'all' && (
                    <span className='filterOptsLabel'>
                        {getFilterLabel(filters.state)}
                        <X onClick={() => setFilters({ ...filters, state: 'all' })} />
                    </span>
                )}
                {filters.state === 'all' && <button className='filterOptsBtn' onClick={() => setShowSkillFilter(!showSkillFilter)}>Any Status  <ChevronDown /></button>}
                {showSkillFilter && (
                    <div className='filterOptsMenu'>
                        <ul>
                            <li onClick={() => setFilters({ ...filters, state: 'all' })}>Any Status</li>
                            <li onClick={() => setFilters({ ...filters, state: ASSESSMENT_STATE.completed })}>Completed</li>
                            <li onClick={() => setFilters({ ...filters, state: ASSESSMENT_STATE.in_progress })}>In progress</li>
                            <li onClick={() => setFilters({ ...filters, state: ASSESSMENT_STATE.not_started })}>Not started</li>
                        </ul>
                    </div>
                )}
            </div>

            <div className='filterOpts'>
                <p>Role Match</p>
                {filters.externalState !== 'all' && (
                    <span className='filterOptsLabel'>
                        {getFilterLabel(filters.externalState)}
                        <X onClick={() => setFilters({ ...filters, externalState: 'all' })} />
                    </span>
                )}
                {filters.externalState === 'all' && <button className='filterOptsBtn' onClick={() => setShowJobFilter(!showJobFilter)}>Any Status <ChevronDown /></button>}
                {showJobFilter && (
                    <div className='filterOptsMenu'>
                        <ul>
                            <li onClick={() => setFilters({ ...filters, externalState: 'all' })}>Any status</li>
                            <li onClick={() => setFilters({ ...filters, externalState: ASSESSMENT_STATE.completed })}>Completed</li>
                            <li onClick={() => setFilters({ ...filters, externalState: ASSESSMENT_STATE.in_progress })}>In progress</li>
                            <li onClick={() => setFilters({ ...filters, externalState: ASSESSMENT_STATE.not_started })}>Not started</li>
                        </ul>
                    </div>
                )}
            </div>
        </>
    )
}

export default FilterMenus;