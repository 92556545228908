import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_CANDIDATE, RESEND_CANDIDATE_INVITE, CANDIDATE_SYNC } from './services/graphql'
import { ASSESSMENT_STATE } from '../../services/constants';

import { navigate } from '@reach/router';
import { ArrowLeft, Edit3, ExternalLink } from 'react-feather';
import FormErrors from '../../components/FormErrors';
import SaveConfirm from '../../components/SaveConfirm';
import Modal from '../../components/Modal';

import AnswersQuery from './components/Answers/AnswersQuery';
import EditCandidate from './components/EditCandidate';
import RoleMatchResult from './components/RoleMatchResult';


import styles from './Candidate.module.scss'
import cx from 'classnames';


const Candidate = ({ candidateId }) => {

    const [editCandidate, setEditCandidate] = useState(false);

    const hostname = window?.location?.hostname;
    const link = hostname && (hostname === 'localhost' ? 'http://localhost:3000' : hostname.includes('staging') ? 'https://staging-my.cuttingedge.app' : 'https://my.cuttingedge.app')

    const { data, error, loading } = useQuery(GET_CANDIDATE, {
        fetchPolicy: 'network-only',
        variables: {
            id: candidateId
        },
    });

    const [resendDone, setResendDone] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [syncError, setSyncError] = useState(null);

    const [resendInvite, { loading: resending }] = useMutation(RESEND_CANDIDATE_INVITE, {
        onCompleted: data => {
            if (data.resendCandidateInvite.errors && data.resendCandidateInvite.errors.length !== 0) {
                setFormErrors(data.resendCandidateInvite.errors);
                return;
            }
            if (data.resendCandidateInvite && data.resendCandidateInvite.reset) {
                setResendDone(true);
            }
        },
    })

    const [candidateSync, { loading: syncing }] = useMutation(CANDIDATE_SYNC, {
        onCompleted: data => {
            if (data.candidateSync.errors && data.candidateSync.errors.length) {
                setSyncError(data.candidateSync.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_CANDIDATE, variables: { id: candidateId } }]
    })


    if (loading) return <div>Loading...</div>;

    if (error) return <p>Oops, something went wrong...</p>

    if (data && data.candidate) {
        const { candidate } = data;

        return (
            <>
                <div className={styles.candidateHeader}>
                    <span onClick={() => navigate(-1)} className={styles.candidateHeaderBack}>
                        <ArrowLeft className='u-m-right-2' size="16" />
                        {candidate.firstName} {candidate.lastName}
                    </span>
                    <span className='u-m-left-2 u-m-right-2'>{candidate.email}</span>
                    <button className='button buttonText' onClick={() => setEditCandidate(true)}><Edit3 size="20" /> </button>
                </div>

                {editCandidate && (
                    <Modal closeModal={() => setEditCandidate(false)}>
                        <EditCandidate closeEdit={() => setEditCandidate(false)} candidate={candidate} />
                    </Modal>
                )}

                {candidate.needsSync &&
                    <button
                        disabled={syncing}
                        className='button btn-primary'
                        onClick={() => candidateSync({ variables: { candidateId } })}
                    >{syncing ? 'Syncing...' : 'Sync with FHG'}</button>
                }
                {syncError && <p>{syncError}</p>}

                {candidate.externalAssessment && <RoleMatchResult candidate={candidate} />}

                {candidate.assessment && (
                    <>
                        <hr className='separator u-m-top-3 u-m-base-3' />

                        <div className={styles.assessmentHeader}>
                            <h3 className={styles.assessmentHeaderTitle}>Computer Skills</h3>
                            {candidate.assessment ?
                                <div className={styles.assessmentHeaderSide}>
                                    Access code: <b className='u-m-left-1'>{candidate.assessment.accessCode}</b>
                                    {link && <a target="blank" className={styles.userLink} rel="noopener noreferrer" href={link}><ExternalLink size="18" /></a>}
                                </div>
                                : <p>No assessment</p>}
                        </div>
                        {
                            candidate.candidateScores.length > 0 ?
                                <AnswersQuery candidate={candidate} /> :
                                <>
                                    <div className={cx(styles.assessmentBar, styles.assessmentBarEmpty)}>
                                        {candidate.assessment.state === ASSESSMENT_STATE.not_started ? "Assessment not started" : "Assessment In progress"}

                                        {candidate.assessment.state === ASSESSMENT_STATE.not_started && (
                                            <>

                                                {resendDone ? <SaveConfirm classNames='u-m-top-3' message="Done!" hideConfirm={setResendDone} /> :
                                                    candidate.assessment.state === ASSESSMENT_STATE.not_started && <button
                                                        className={styles.resendBtn}
                                                        onClick={() => resendInvite({
                                                            variables: { candidateId: candidate.id }
                                                        })}
                                                    >{resending ? "Resending..." : "Resend invite"}</button>
                                                }

                                            </>
                                        )}
                                    </div>
                                    {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                                </>
                        }
                    </>
                )}
            </>
        )
    }
    return null;
}

export default Candidate;