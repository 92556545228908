import { gql } from 'apollo-boost'


export const UPDATE_PASSWORD = gql`
mutation updatePassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    updatePassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
        user {
            id
            email
        }
        errors
    }
}`


export const RESET_PASSWORD = gql`
mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
        reset
        errors
    }
}
`;

