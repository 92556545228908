import React, { useState } from "react";
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_GROUP } from "../../services/graphql";
import { UPDATE_GROUP, GET_JOBS_LIST } from "../../../Groups/services/graphql";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import styles from './RoleMatch.module.scss';
import { AlertTriangle } from "react-feather";


const AddJobRole = ({ group, enterpriseId }) => {

    const [formErrors, setFormErrors] = useState([]);

    const { data, loading, error } = useQuery(GET_JOBS_LIST);


    const [updateGroup, { loading: updatingGroup }] = useMutation(UPDATE_GROUP, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.updateGroup.errors && data.updateGroup.errors.length !== 0) {
                setFormErrors(data.create.errors);
                return;
            }
        },
        refetchQueries: [{ query: GET_GROUP, variables: { id: group.id } }]
    });

    const schema = Yup.object().shape({
        externalAssessmentJobCode: Yup.string().required('Please select role!')

    });


    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
    });


    const onSubmit = values => {
        const { externalAssessmentJobCode } = values;
        updateGroup({
            variables: {
                enterpriseId,
                groupId: group.id,
                name: group.name,
                needsLocalAssessment: group.needsLocalAssessment,
                needsExternalAssessment: true,
                externalAssessmentJobCode,
                active: group.active
            }
        })
    }


    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) return <p>Loading</p>

    if (data && data.externalJobsList) {
        return (
            <div className={styles.addRole}>

                <AlertTriangle size={50} className={styles.addRoleIcon} />
                <p className="u-m-top-3 u-m-base-3">Please choose a job role to benchmark</p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="basic-form__group">
                        <select className="basic-form__text-select" ref={register} name="externalAssessmentJobCode">
                            <option value=''>Please Select a Job Role</option>
                            {data.externalJobsList.map(job => (
                                <option key={`j-${job.id}`} value={job.code}>{job.name}</option>
                            ))}
                        </select>
                    </div>
                    {errors.externalAssessmentJobCode && <p className="basic-form__hint">{errors.externalAssessmentJobCode.message}</p>}

                    {formErrors.length !== 0 && <p>Something went wrong</p>}
                    <div className="u-m-top-3">
                        <button type="submit" disabled={updatingGroup} className="button">{updatingGroup ? 'Saving...' : 'Add Job Role'}</button>
                    </div>
                </form>
            </div>
        )
    }
    return null;
}

export default AddJobRole;