import { gql } from 'apollo-boost'

export const GET_CANDIDATES = gql`
query enterprise($id: ID!, $offset: Int, $state: Int, $externalState: Int) {
    enterprise(id: $id) {
        id
        candidates(offset: $offset, state: $state, externalState: $externalState) {
            id
            firstName
            lastName
            email
            createdAt
            assessment {
                id
                state
            }
            candidateScores {
                id
                score
                skillset
                totalAnswers
            }
            externalAssessment {
                id
                status
                jobScore {
                    id
                    overallMatchingScore
                }
            }
        }
    }
}`;

export const GET_S3_UPLOAD_URL_QUERY = gql`
    query s3UploadUrl($name: String!) {
        s3UploadUrl(name: $name)
    }
`

export const SEARCH_CANDIDATES = gql`
    query searchCandidates($id: ID!, $query: String) {
    enterprise(id: $id) {
        id
        candidates(query: $query) {
            id
            firstName
            lastName
            email
            createdAt
            assessment {
                id
                state
            }
            candidateScores {
                id
                score
                totalAnswers
                skillset
            }
            externalAssessment {
                id
                status
                jobScore {
                    id
                    overallMatchingScore
                }
            }
        }
    }
}`;

export const IMPORT_CHECK = gql`
    query importFile($id: ID!) {
        importFile(id: $id) {
            id
            imported
            status
            errors
        }
    }
`

export const CREATE_CANDIDATE = gql`
mutation createCandidate($enterpriseId: ID!, $firstName: String!, $lastName: String! $email: String!, $groupId: ID) {
    createCandidate(enterpriseId: $enterpriseId, firstName: $firstName, lastName: $lastName, email: $email, groupId: $groupId) {
        candidate {
            id
            firstName
            lastName
            email
            createdAt
            assessment {
                id
                state
            }
            candidateScores {
                id
                score
                totalAnswers
                skillset
            }
        }
        errors
    }
}
`;

export const IMPORT_CANDIDATES = gql`
mutation importCandidatesFromXlsx($enterpriseId: ID!, $fileName: String!, $groupId: ID) {
    importCandidatesFromXlsx(enterpriseId: $enterpriseId, fileName: $fileName, groupId: $groupId) {
        importFile {
            id
            total
            imported
            status
        }
        errors
    }
}`

