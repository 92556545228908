import React, { Fragment } from 'react';

const ResetSent = () => {
    return (
        <Fragment>
            <h1 className="light text-primary u-m-base-3">Reset link sent</h1>
            <p>Click the reset email link to set a new password</p>
        </Fragment>
    )
}

export default ResetSent;
