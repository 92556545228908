import React, { useState } from 'react';
import Modal from '../Modal';
import { Check, Minus, X } from 'react-feather';
import { getModifier } from '../../services/utils';
import CandidateMatchModal from './CandidateMatchModal';
import { useWindowSize } from 'react-use';

import cx from 'classnames';
import styles from './CandidateRoleMatch.module.scss';

const CandidateRoleMatch = ({ candidate, skillsets }) => {

    const [showMatches, setShowMatches] = useState(false);
    const [showMobileInfo, setShowMobileInfo] = useState(false);
    
    const breakpoint = 768;
    const { width } = useWindowSize();

    const matchingScore = candidate.externalAssessment.jobScore;
    const otherMatchingScores = candidate.externalAssessment.candidateExternalScores.filter(sc => sc.id !== candidate.job.id);

    const getIcon = (score, targetScore) => {
        if (score / targetScore < 0.7) {
            return <X />;
        }
        if (score / targetScore >= 0.8) {
            return <Check />;
        }
        return <Minus />;
    }



    return (
        <>

            <li {...(width < breakpoint && { onClick: () => setShowMobileInfo(true) })} className={cx(styles.candidateItem, {'link': width < breakpoint})}>
                <div className={styles.candidateItemInner}>
                    <div className={styles.candidateItemMain}>
                        <h4 className='u-text-primary'>{candidate.firstName} { candidate.lastName}</h4>
                        {otherMatchingScores.length === 0 ? <p>Matches 0 other job roles </p> :
                            <button disabled={width < breakpoint} className={cx('btn-reset', { 'link link-underline': width > breakpoint } )} onClick={() => setShowMatches(true)}>Matches {otherMatchingScores.length} other job roles </button>
                        }
                    </div>
                    <div className={styles.candidateItemScoreWrapper}>

                        <div className={cx(styles.candidateItemScore, styles.candidateItemScoreOverall, { [styles.candidateItemScoreIncomplete]: !candidate.externalAssessment.finishedAt })}>
                            {
                                candidate.externalAssessment.finishedAt ?
                                    (
                                        <>
                                            <div className={styles[getModifier(matchingScore?.overallMatchingScore, 100)]}>
                                                {getIcon(matchingScore?.overallMatchingScore, 100)}
                                            </div>
                                            <p className={styles.overallScoreText}>{Math.round(matchingScore?.overallMatchingScore)}%</p>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <div className={styles.noScore}>
                                                {getIcon()}
                                            </div>
                                            <p>{candidate.externalAssessment.status === 1 ? 'In progress' : 'Not started'}</p>
                                        </>
                                    )
                            }
                        </div>
                        {
                            matchingScore && matchingScore.details.length ? matchingScore.details.map(cs => {
                                const { actualScore, id, requiredScore } = cs;
                                return (
                                    <div
                                        className={styles.candidateItemScore}
                                        key={`u_${candidate.id}_cs_${id}`}
                                    >
                                        <div className={styles[getModifier(actualScore, requiredScore)]}>
                                            {getIcon(actualScore, requiredScore)}
                                        </div>
                                        <p>{actualScore}</p>
                                    </div>
                                )
                            }) : skillsets.map(ss => {
                                return (
                                    <div
                                        className={cx(styles.candidateItemScore, { [styles.candidateItemIncomplete]: !candidate.externalAssessment.finishedAt })}
                                        key={`u_${candidate.id}_ss_${ss.id}`}
                                    >
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </li>
            {showMobileInfo && (
                <Modal closeModal={() => setShowMobileInfo(false)}>
                    <CandidateMatchModal candidate={candidate} />
                </Modal>
            )}
            {
                showMatches && (
                    <Modal closeModal={() => setShowMatches(false)}>
                        <CandidateMatchModal candidate={candidate} />
                    </Modal>
                )
            }
        </>
    )
}

export default CandidateRoleMatch;