import React, { useState, useContext } from 'react';

import { AuthContext } from '../../services/user/AuthProvider';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import FormErrors from '../../components/FormErrors';
import { useMutation } from '@apollo/react-hooks';
import { EDIT_ADMIN } from './services/graphql';
import styles from './Profile.module.scss';
import SaveConfirm from '../../components/SaveConfirm';

const Profile = () => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);

    const [editAdmin, { loading }] = useMutation(EDIT_ADMIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.editAdmin.errors && data.editAdmin.errors.length !== 0) {
                setFormErrors(data.editAdmin.errors);
                return;
            }
            if (data && data.editAdmin.user.id) {
                setCurrentUser(data.editAdmin.user);
                setShowSaveConfirm(true);
            }
        }
    });

    const schema = Yup.object().shape({
        firstName: Yup.string()
            .required('Please enter first name'),
        lastName: Yup.string()
            .required('Please enter last name '),
        email: Yup.string().email()
            .required('Please enter a valid email address.'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { firstName, lastName, email } = values;
        editAdmin({
            variables: {
                userId: currentUser.id,
                firstName,
                lastName,
                email,
            }
        });
    }

    return (
        <div>
            <div className='pageHeader'>
                <h1 className='u-m-right-2'>Edit profile</h1>
            </div>
            <form className={styles.profile} onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group">
                    <input
                        name="firstName"
                        placeholder="First Name"
                        defaultValue={currentUser.firstName}
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="lastName"
                        placeholder="Last Name"
                        defaultValue={currentUser.lastName}
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="email"
                        placeholder="Email"
                        defaultValue={currentUser.email}
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <button type="submit" className="button">{loading ? 'Saving' : 'Save'}</button>
                {showSaveConfirm && <SaveConfirm hideConfirm={setShowSaveConfirm} />}

            </form>
        </div>
    )
}

export default Profile