import React, { useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import ResetPassword from './components/ResetPassword';
import FormErrors from '../../components/FormErrors';
import Modal from '../../components/Modal';
import { AuthContext } from '../../services/user/AuthProvider';
import { LOGIN } from './services/graphql';
import styles from './Login.module.scss'

const Login = ({ location }) => {

    const [formErrors, setFormErrors] = useState([]);
    const client = useApolloClient();
    const auth = useContext(AuthContext);
    const [modalForgotPass, setModalForgotPass] = useState(false);

    const [login, { loading: loggingIn }] = useMutation(LOGIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.logIn.errors && data.logIn.errors.length !== 0) {
                setFormErrors(data.logIn.errors);
                return;
            }
            if (data.logIn.user && data.logIn.user.id && data.logIn.token) {
                ls('cutting-edge-admin-token', data.logIn.token);
                ls('cutting_edge_user', JSON.stringify(data.logIn.user));
                auth.setCurrentUser(data.logIn.user);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    const schema = Yup.object().shape({
        email: Yup.string().email()
            .required('Please enter your email.'),
        password: Yup.string()
            .required('Please enter your password.')
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { email, password } = values;
        login({
            variables: {
                email,
                password
            }
        });
    }

    return (
        <div className={styles.col}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className="u-m-base-3">Login</h1>
                {location.state && location.state.password_updated && (
                    <h4 className='u-m-base-3'>Password set succesfully, please login</h4>
                )}
                <div className="basic-form__group">
                    <input
                        name="email"
                        placeholder="Email"
                        className="basic-form__text-box"
                        ref={register}
                        type="email"
                    />
                </div>
                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="password"
                        placeholder="Password"
                        className="basic-form__text-box"
                        autoComplete="off"
                        ref={register}
                        type="password"
                    />
                </div>
                {errors.password && <p className="basic-form__hint">{errors.password.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className="u-display-flex u-align-center">
                    <button type="submit" className="button">{loggingIn ? 'Logging in...' : 'Login'}</button>
                    <button type='button' onClick={() => setModalForgotPass(true)} className='button buttonText'>Forgot password</button>
                </div>
            </form>
            {
                modalForgotPass && (
                    <Modal closeModal={() => setModalForgotPass(false)}>
                        <ResetPassword closeModal={() => setModalForgotPass(false)} />
                    </Modal>
                )
            }
        </div>
    );
}

export default Login;
