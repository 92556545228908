import React from 'react';
import { getCompleted, getMatched, getNearMatched, getNotMatched, CANDIDATES_FILTER } from '../../../../services/utils';
import styles from './RoleMatch.module.scss';
import cx from 'classnames';

const GroupStats = ({ group, statFilter, setStatFilter }) => {

    const externalAssessmentCandidates = group.candidates.filter(c => c.externalAssessment);

    const numCandidates = externalAssessmentCandidates.length;
    const numCompleted = getCompleted(externalAssessmentCandidates).length;

    return (
        <div className={styles.stats}>
            <p className={styles.statsJobTitle}>{group.job.name}</p>
            <ul className={styles.statsList}>
                <li onClick={() => setStatFilter(CANDIDATES_FILTER.all)} className={styles.statsItem}>
                    <p className={styles.statsTitle}>Candidates</p>
                    <div className={cx(styles.statsCountHolder, { [styles.statsCountHolderSelected]: statFilter === CANDIDATES_FILTER.all })}>
                        <div className={styles.statsCount}>{numCandidates || '-'}</div>
                    </div>
                </li>
                <li onClick={() => setStatFilter(CANDIDATES_FILTER.completed)} className={styles.statsItem}>
                    <p className={styles.statsTitle}>Completed</p>
                    <div className={cx(styles.statsCountHolder, { [styles.statsCountHolderSelected]: statFilter === CANDIDATES_FILTER.completed })}>
                        <div className={styles.statsCount}>{numCandidates ? numCompleted : '-'}</div>
                    </div>
                </li>
                <li onClick={() => setStatFilter(CANDIDATES_FILTER.matched)} className={styles.statsItem}>
                    <p className={styles.statsTitle}>Match</p>
                    <div className={cx(styles.statsCountHolder, { [styles.statsCountHolderSelected]: statFilter === CANDIDATES_FILTER.matched })}>
                        <div className={cx(styles.statsCount, styles.statsCountMatch)}>{numCompleted ? getMatched(externalAssessmentCandidates).length : '-'}</div>
                    </div>
                </li>
                <li onClick={() => setStatFilter(CANDIDATES_FILTER.near_matched)} className={styles.statsItem}>
                    <p className={styles.statsTitle}>Near Match</p>
                    <div className={cx(styles.statsCountHolder, { [styles.statsCountHolderSelected]: statFilter === CANDIDATES_FILTER.near_matched })}>
                        <div className={cx(styles.statsCount, styles.statsCountNearMatch)}>{numCompleted ? getNearMatched(externalAssessmentCandidates).length : '-'}</div>
                    </div>
                </li>
                <li onClick={() => setStatFilter(CANDIDATES_FILTER.not_matched)} className={styles.statsItem}>
                    <p className={styles.statsTitle}>No Match</p>
                    <div className={cx(styles.statsCountHolder, { [styles.statsCountHolderSelected]: statFilter === CANDIDATES_FILTER.not_matched })}>
                        <div className={cx(styles.statsCount, styles.statsCountFail)}>{numCompleted ? getNotMatched(externalAssessmentCandidates).length : '-'}</div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default GroupStats;