
export const getPercScores = scores => {
    let score = 0;
    let total = 0;
    scores.forEach(e => {
        score = score + e.score;
        total = total + e.totalAnswers;
    })
    return scores.length > 0 ? `${((score / total) * 100).toFixed()}%` : '0%';
}


export const ALL_CANDIDATES = "all";
export const COMPLETED_CANDIDATES = "completed";
export const MATCHED_CANDIDATES = "matched";
export const NEAR_MATCHED_CANDIDATES = "near matched";
export const NOT_MATCHED_CANDIDATES = "not matched";

export const CANDIDATES_FILTER = {
    'all': 1,
    'completed': 2,
    'matched': 3,
    'near_matched': 4,
    'not_matched': 5
}

export const getCandidates = (candidates, filter) => {
    switch (filter) {
        case CANDIDATES_FILTER.completed:
            return getCompleted(candidates);
        case CANDIDATES_FILTER.matched:
            return getMatched(candidates);
        case CANDIDATES_FILTER.near_matched:
            return getNearMatched(candidates);
        case CANDIDATES_FILTER.not_matched:
            return getNotMatched(candidates);
        default:
            return candidates.filter(c => c.externalAssessment);
    }
}

export const getCompleted = candidates => {
    return candidates.filter(c => c.externalAssessment && c.externalAssessment.status === 2);
}

export const getMatched = candidates => {
    return candidates.filter(c => c?.externalAssessment?.status === 2 && c.externalAssessment?.jobScore?.overallMatchingScore >= 80);
}

export const getNearMatched = candidates => {
    return candidates.filter(c =>  c?.externalAssessment?.status === 2 && c.externalAssessment?.jobScore?.overallMatchingScore >= 70 && c.externalAssessment.jobScore.overallMatchingScore < 80);
}

export const getNotMatched = candidates => {
    return candidates.filter(c => c?.externalAssessment?.status === 2 && c.externalAssessment?.jobScore?.overallMatchingScore < 70);
}

export const getModifier = (score, targetScore) => {
    if (score / targetScore < 0.7) {
        return 'scoreFail';
    }
    if (score / targetScore >= 0.8) {
        return 'scorePass';
    }
    return 'scoreNearPass';
}
