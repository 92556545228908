import React from 'react'
import { CheckCircle, XCircle, Clock, CheckSquare } from 'react-feather';
import styles from '../../Candidate.module.scss';

const SkillAnswers = ({ score, skillAnswers }) => {

    return (
        <div className='u-m-base-3'>
            <div className={styles.skillTitle}>
                <h3 className='u-m-base-0'>{score.skillset}</h3>
                <div className={styles.skillTitleScores}>
                    {`${(score.score / score.totalAnswers).toFixed(2) * 100}%`} ({score.score}/{score.totalAnswers})
                </div>
            </div>
            <ul>
                {skillAnswers.map(answer => {
                    return (
                        <li key={`answer-${answer.id}`}>
                            <div className={styles.skill}>
                                <div className={styles.skillDetail}>
                                    {answer.passed ? <CheckCircle stroke="#F8B449" /> : <XCircle stroke="#F84949" />}
                                    <p style={{ marginLeft: '10px' }}>{answer.question.description}</p>
                                </div>
                                <div className={styles.skillBlock}>
                                    <Clock stroke="#FFFFFF" />
                                    <p style={{ marginLeft: '5px' }}>{answer.duration}s</p>
                                </div>
                                <div className={styles.skillBlock}>
                                    <CheckSquare stroke="#FFFFFF" />
                                    <p style={{ marginLeft: '5px' }}>{answer.attempts}</p>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}


export default SkillAnswers