import React, { useContext, useEffect, useState, useRef } from 'react'
import { useQuery, useMutation, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import { ArrowLeft, Folder, XCircle, Upload, Loader, Search } from 'react-feather';
import { AuthContext } from '../../services/user/AuthProvider';
import AddBtn from '../../components/AddBtn'
import axios from 'axios';
import { GET_S3_UPLOAD_URL_QUERY, IMPORT_CANDIDATES, IMPORT_CHECK } from '../Dashboard/services/graphql';
import { GET_GROUP, SEARCH_CANDIDATES, GROUP_SYNC } from './services/graphql';
import GroupActionCable from './services/GroupActionCable';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';
import Modal from '../../components/Modal'
import FilterMenus from '../../components/FilterMenus';
import CandidateListItem from '../Candidate/components/CandidateListItem';
import RoleMatchGroup from './components/RoleMatchGroup';
import CreateCandidate from './components/CreateCandidate';

import example from '../../images/csv_example.png'

import styles from '../Groups/Groups.module.scss';
import styles2 from './Group.module.scss';
import cx from 'classnames';

const Group = ({ groupId }) => {

    const { currentUser } = useContext(AuthContext);
    const enterpriseId = currentUser.enterprise.id;
    const client = useApolloClient();
    const [showUpload, setShowUpload] = useState(false)
    const [showAddMenu, setShowAddMenu] = useState(false);
    const [showAddIndividual, setShowIndividual] = useState(false);
    const [uploadError, setUploadError] = useState([])
    const [uploading, setUploading] = useState(false);
    const [syncError, setSyncError] = useState(null);

    const [selectedTab, setSelectedTab] = useState('all');

    const [searchTerm, setSearchTerm] = useState("");
    const [placeHolderTerm, setPlaceHolderTerm] = useState("");
    const [searchResults, setSearchResults] = useState("");

    const [filterData, setFilterData] = useState(null);
    const [jobFilterData, setJobFilterData] = useState(null);

    const [candidates, setCandidates] = useState([]);

    const { data, error, loading } = useQuery(GET_GROUP, {
        variables: {
            id: groupId,
        },
    });

    useEffect(() => {
        if (data && data.group && data.group.candidates) {
            setCandidates(data.group.candidates);
        }
    }, [data]);


    const [getImportInfo, { stopPolling }] = useLazyQuery(IMPORT_CHECK, {
        fetchPolicy: 'network-only',
        pollInterval: 2000,
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.importFile.status === 1) {
                stopPolling()
                client.query({
                    query: GET_GROUP,
                    fetchPolicy: 'network-only',
                    variables: {
                        id: groupId
                    },
                }).then(r => {
                    setCandidates(r.data.group.candidates);
                    setUploading(false)
                    setShowUpload(false)
                })
            }
        },
    });

    const [onSave] = useMutation(IMPORT_CANDIDATES, {
        update: (_, { data }) => {
            if (data.importCandidatesFromXlsx.errors && data.importCandidatesFromXlsx.errors.length !== 0) {
                setUploadError(data.importCandidatesFromXlsx.errors[0]);
                return;
            }
            if (data.importCandidatesFromXlsx.importFile.errors && data.importCandidatesFromXlsx.importFile.errors.length !== 0) {
                setUploadError(`Imported ${data.importCandidatesFromXlsx.importFile.imported} out of ${data.importCandidatesFromXlsx.importFile.total}. ${data.importCandidatesFromXlsx.importFile.errors[0]}`);
                return;
            }
            if (data.importCandidatesFromXlsx.importFile) {
                getImportInfo({
                    variables: { id: data.importCandidatesFromXlsx.importFile.id }
                })
            }
        },
    })

    const [groupSync, { loading: syncing }] = useMutation(GROUP_SYNC, {
        onCompleted: data => {
            if (data.groupSync.errors && data.groupSync.errors.length) {
                setSyncError(data.groupSync.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_GROUP, variables: { id: groupId } }]
    })

    const onSelectFile = e => {
        setUploading(true)
        const fileName = `candidates-list-${enterpriseId}-${new Date().getTime()}.xlsx`;
        const file = e.target.files[0]
        client.query({
            query: GET_S3_UPLOAD_URL_QUERY,
            variables: {
                name: fileName
            }
        }).then(r => {
            const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
            axios.put(r.data.s3UploadUrl, file, options).then(s3r => {
                onSave({
                    variables: {
                        fileName,
                        enterpriseId,
                        groupId
                    }
                });
            })
        })
    }



    const [searchCandidates, { loading: searchLoading }] = useLazyQuery(SEARCH_CANDIDATES, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.group) {
                setSearchResults(data.group)
            }
        }
    });

    const searchSubmit = e => {
        e.preventDefault()
        setPlaceHolderTerm(searchTerm);
        searchCandidates({
            variables: {
                id: groupId,
                query: searchTerm
            }
        })
    }

    const clearSearch = () => {
        setSearchTerm("")
        setSearchResults(null)
    }


    const candidatesRef = useRef();
    candidatesRef.current = candidates;

    const updateCandidate = data => {
        let newCandidates = [];
        candidatesRef.current.forEach(candidate => {
            if (data.candidate_id === candidate.id) {
                newCandidates.push({
                    ...candidate,
                    externalAssessment: {
                        status: 0
                    }
                })
            } else {
                newCandidates.push(candidate);
            }
        });
        setCandidates(newCandidates);
    }


    const renderFilteredCandidates = (candidates) => {
        if (filterData === null && jobFilterData === null) {
            return candidates;
        }
        if (jobFilterData !== null && filterData !== null) {
            return candidates.filter(c => c?.externalAssessment?.status === +jobFilterData).filter(c => c?.assessment?.state === +filterData);
        }
        if (filterData !== null) {
            return candidates.filter(c => c?.assessment?.state === +filterData);
        }
        if (jobFilterData !== null) {
            return candidates.filter(c => c?.externalAssessment?.status === +jobFilterData);
        }
        return candidates;
    }

    if (loading) { return <p>Loading...</p> }

    if (error) { return <p>Something went wrong...</p> }

    if (data) {
        return (
            <>
                <GroupActionCable groupId={data.group.id} onReceived={updateCandidate} />
                {showUpload ?
                    <div>
                        <div className={styles.uploadHeader}>
                            <h1 className="u-m-top-2">Upload Candidate List</h1>
                            <XCircle className={styles.closeUpload} onClick={() => setShowUpload(false)} />
                        </div>
                        <p>Please upload the list as an excel file (.xlsx).</p>
                        <p>It should only contain the candidates details shown below.</p>
                        <img src={example} alt='' className={styles.csvExample} />
                        <p>Your spreadsheet should contain only 3 columns in the following order:</p>
                        <ul className="u-m-base-2">
                            <li>first_name</li>
                            <li>last_name</li>
                            <li>email_address</li>
                        </ul>
                        {uploading ? <label className="button buttonIcon">Uploading File...</label> :
                            <>
                                <input style={{ display: 'none' }} className={styles.inputFile} id='list' name='list' type='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onChange={onSelectFile} />
                                <label className="button buttonIcon" htmlFor='list'><Upload size='20' style={{ marginRight: '5px' }} />Select File</label>
                            </>
                        }
                        {uploadError.length !== 0 && <p>Something went wrong</p>}
                    </div>
                    :
                    <>
                        <span onClick={() => navigate(-1)} className={styles.backLink}><ArrowLeft style={{ marginRight: '10px' }} size="16" /><Folder size="16" style={{ marginRight: '5px' }} />
                            {data.group.name}
                        </span>

                        <Tabs className='u-m-base-3'>
                            <Tab onClick={() => setSelectedTab('all')} selected={selectedTab === 'all'}>All</Tab>
                            <Tab onClick={() => setSelectedTab('roleMatch')} selected={selectedTab === 'roleMatch'}>Role Match</Tab>
                        </Tabs>

                        {data.group.needsSync &&
                            <button
                                disabled={syncing}
                                className='button btn-primary'
                                onClick={() => groupSync({ variables: { groupId } })}
                            >{syncing ? 'Syncing...' : 'Sync with FHG'}</button>
                        }
                        {syncError && <p>{syncError}</p>}

                        {selectedTab === 'all' ?
                            <>
                                <div className='pageHeader'>
                                    <div className='pageHeaderMain'>
                                        <form className='basic-form__search' onSubmit={(e) => searchSubmit(e)}>
                                            <input className={cx('basic-form__text-box', 'basic-form__search__input')} placeholder="Find Candidate" type="text" name="search" value={searchTerm} onChange={(e) => setSearchTerm(e.currentTarget.value)} />
                                            <button type="submit" className='basic-form__search__btn'>{searchLoading ? <Loader size="16" /> : <Search size="16" />}</button>
                                        </form>
                                        <FilterMenus
                                            filterData={filterData}
                                            setFilterData={setFilterData}
                                            jobFilterData={jobFilterData}
                                            setJobFilterData={setJobFilterData}
                                            visibleJobFilter={data.group.needsExternalAssessment}
                                            visibleSkillFilter={data.group.needsLocalAssessment}
                                        />

                                    </div>
                                    <div className={styles.dropdownHolder}>
                                        <AddBtn action={() => setShowAddMenu(!showAddMenu)} />
                                        {showAddMenu &&
                                            <>
                                                <ul className={cx(styles.dropdown)}>
                                                    <li onClick={() => { setShowIndividual(true); setShowAddMenu(false) }}>Create New Candidate</li>
                                                    <li onClick={() => { setShowUpload(true); setShowAddMenu(false) }}>Upload Excel File</li>
                                                </ul>
                                            </>
                                        }
                                    </div>
                                </div>
                                {searchResults ?
                                    <>
                                        <span className={styles.backLink} onClick={() => clearSearch()}><ArrowLeft style={{ marginRight: '10px' }} size="16" /> Back</span>
                                        <h1>Search results for '{placeHolderTerm}': {searchResults.candidates.length}</h1>
                                        {searchResults.candidates.length > 0 &&
                                            <ul className={styles2.candidateHeader}>
                                                <li>Computer Skills Test</li>
                                                <li>Job Role Match</li>
                                            </ul>
                                        }
                                        <ul>
                                            {searchResults.candidates.map(candidate => {
                                                return <CandidateListItem key={`search-${candidate.id}`} candidate={candidate} />
                                            })}
                                        </ul>
                                    </>
                                    :
                                    <>
                                        {candidates.length > 0 ?
                                            <>
                                                <ul className={styles2.candidateHeader}>
                                                    <li>Computer Skills Test</li>
                                                    <li>Job Role Match</li>
                                                </ul>
                                                <ul className='u-m-base-3'>
                                                    {renderFilteredCandidates(candidates).map(candidate => <CandidateListItem key={`candidate-${candidate.id}`} candidate={candidate} />)}
                                                </ul>
                                            </>
                                            :
                                            <p>You haven't added any candidates to this group.</p>
                                        }
                                    </>
                                }
                            </> : <RoleMatchGroup enterpriseId={enterpriseId} group={data.group} />
                        }
                    </>
                }
                {showAddIndividual &&
                    <Modal closeModal={() => setShowIndividual(false)}>
                        <CreateCandidate close={() => setShowIndividual(false)} groupId={groupId} enterpriseId={enterpriseId} />
                    </Modal>
                }

            </>
        )
    }
    return null;
}

export default Group