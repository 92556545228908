import { gql } from 'apollo-boost'
import {adminQuery } from '../../../services/constants';

export const EDIT_ADMIN = gql`
mutation editAdmin($userId: ID!, $firstName: String!, $lastName: String! $email: String!) {
    editAdmin(userId: $userId, firstName: $firstName, lastName: $lastName, email: $email) {
        user {
            ${adminQuery}
        }
        errors
    }
}
`;

