import React, { useContext, useState, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { AuthContext } from '../../services/user/AuthProvider';
import { useQuery, useMutation, useLazyQuery, useApolloClient } from '@apollo/react-hooks';

import { Search, ArrowLeft, XCircle, Upload, Loader } from 'react-feather';
import axios from 'axios';
import { CREATE_CANDIDATE, GET_CANDIDATES, SEARCH_CANDIDATES, GET_S3_UPLOAD_URL_QUERY, IMPORT_CANDIDATES, IMPORT_CHECK } from './services/graphql';
import Modal from '../../components/Modal'
import CandidateListItem from '../Candidate/components/CandidateListItem';
import styles from './Dashboard.module.scss'

import cx from 'classnames';
import example from '../../images/csv_example.png'
import FilterMenus from './components/FilterMenus';

const PAGE_SIZE = 20;

const Dashboard = () => {

    const { currentUser } = useContext(AuthContext);
    const enterpriseId = currentUser.enterprise.id;
    const client = useApolloClient();
    // const [showAddMenu, setShowAddMenu] = useState(false);
    const [showAddIndividual, setShowIndividual] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [placeHolderTerm, setPlaceHolderTerm] = useState("");
    const [searchResults, setSearchResults] = useState("");
    const [showUpload, setShowUpload] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [candidates, setCandidates] = useState([]);

    const [filterData, setFilterData] = useState({
        offset: 0,
        showBtn: false,
    });

    const [filters, setFilters] = useState({
        state: 'all',
        externalState: 'all'
    })

    const [uploading, setUploading] = useState(false);

    const { data, error, loading } = useQuery(GET_CANDIDATES, {
        variables: {
            id: enterpriseId,
            offset: 0
        },
        onCompleted: data => {
            setCandidates(data.enterprise.candidates);
            setFilterData({ ...filterData, showBtn: data.enterprise.candidates.length === PAGE_SIZE })
        }
    });

    const [loadMoreCandidates, { loading: loadingMore }] = useLazyQuery(GET_CANDIDATES, {
        onCompleted: data => {
            setFilterData({ ...filterData, offset: filterData.offset + PAGE_SIZE, showBtn: data.enterprise.candidates.length === PAGE_SIZE })
            setCandidates(candidates.concat(data.enterprise.candidates));
        },
        fetchPolicy: 'network-only',
    });

    const [filterCandidates, { loading: loadingFilter }] = useLazyQuery(GET_CANDIDATES, {
        onCompleted: data => {
            setFilterData({ ...filterData, showBtn: data.enterprise.candidates.length === PAGE_SIZE })
            setCandidates(data.enterprise.candidates);
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        filterCandidates({
            variables: {
                id: enterpriseId,
                offset: 0,
                state: !isNaN(+filters.state) ? +filters.state : null,
                externalState: !isNaN(+filters.externalState) ? +filters.externalState : null,
            }
        });
    }, [filters])

    const [createCandidate, { loading: createLoading }] = useMutation(CREATE_CANDIDATE, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.createCandidate.errors && data.createCandidate.errors.length !== 0) {
                setFormErrors(data.createCandidate.errors);
                return;
            }
            if (data.createCandidate.candidate && data.createCandidate.candidate.id) {
                setShowIndividual(false);
                setFilterData({ ...filterData, offset: 0 });
                setCandidates([data.createCandidate.candidate, ...candidates]);
            }
        },
        refetchQueries: () => [
            {
                query: GET_CANDIDATES,
                variables: {
                    id: enterpriseId,
                    offset: 0
                },
            }
        ]
    });


    const [getImportInfo, { loading: checkImportLoading, error: checkImportError, data: checkImportdata, stopPolling }] = useLazyQuery(IMPORT_CHECK, {
        fetchPolicy: 'network-only',
        pollInterval: 2000,
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.importFile.status === 1) {
                stopPolling()
                client.query({
                    query: GET_CANDIDATES,
                    fetchPolicy: 'network-only',
                    variables: {
                        id: enterpriseId,
                        offset: 0
                    },
                }).then(r => {
                    setCandidates(r.data.enterprise.candidates)
                    setUploading(false)
                    setShowUpload(false)
                })
            }
        },
    });

    const [onSave] = useMutation(IMPORT_CANDIDATES, {
        update: (_, { data }) => {
            if (data.importCandidatesFromXlsx.errors && data.importCandidatesFromXlsx.errors.length !== 0) {
                setUploadError(data.importCandidatesFromXlsx.errors[0]);
                return;
            }
            if (data.importCandidatesFromXlsx.importFile.errors && data.importCandidatesFromXlsx.importFile.errors.length !== 0) {
                setUploadError(`Imported ${data.importCandidatesFromXlsx.importFile.imported} out of ${data.importCandidatesFromXlsx.importFile.total}. ${data.importCandidatesFromXlsx.importFile.errors[0]}`);
                return;
            }
            if (data.importCandidatesFromXlsx.importFile) {
                getImportInfo({
                    variables: { id: data.importCandidatesFromXlsx.importFile.id }
                })
            }
        },
        /*refetchQueries: [{ query: GET_CANDIDATES, variables: { id: enterpriseId }}]*/
    })

    const [searchCandidates, { loading: searchLoading }] = useLazyQuery(SEARCH_CANDIDATES, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.enterprise) {
                setSearchResults(data.enterprise)
            }
            return null;
        }
    });


    const schema = Yup.object().shape({
        firstName: Yup.string()
            .required('Please enter a first name for the candidate.'),
        lastName: Yup.string()
            .required('Please enter a last name for the candidate.'),
        email: Yup.string().email()
            .required('Please enter a valid email address.'),
        search: Yup.string()
    });


    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { firstName, lastName, email } = values;
        createCandidate({ variables: { enterpriseId, firstName, lastName, email, } })
    }

    const searchSubmit = e => {
        e.preventDefault()
        setPlaceHolderTerm(searchTerm);
        searchCandidates({
            variables: {
                id: enterpriseId,
                query: searchTerm
            }
        })
    }

    const clearSearch = () => {
        setSearchTerm("")
        setSearchResults(null)
    }

    const onSelectFile = e => {
        const fileName = `candidates-list-${enterpriseId}-${new Date().getTime()}.xlsx`;
        const file = e.target.files[0]
        setUploading(true)
        client.query({
            query: GET_S3_UPLOAD_URL_QUERY,
            variables: {
                name: fileName
            }
        }).then(r => {
            const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
            axios.put(r.data.s3UploadUrl, file, options).then(s3r => {
                onSave({
                    variables: {
                        fileName,
                        enterpriseId
                    }
                });
            })
        })
    }


    if (error) {
        return <p>Something went wrong&hellip;</p>
    }
    if (loading) {
        return <p>Loading&hellip;</p>
    }
    if (data) {
        return (
            <Fragment>
                {showUpload ?
                    <div>
                        <div className={styles.uploadHeader}>
                            <h1 className="u-m-top-2">Upload Candidate List</h1>
                            <XCircle className={styles.closeUpload} onClick={() => setShowUpload(false)} />
                        </div>
                        <p>Please upload the list as an excel file (.xlsx).</p>
                        <p>It should only contain the candidates details shown below.</p>
                        <img src={example} alt='' className={styles.csvExample} />
                        <p>Your spreadsheet should contain only 3 columns in the following order:</p>
                        <ul className="u-m-base-2">
                            <li>first_name</li>
                            <li>last_name</li>
                            <li>email_address</li>
                        </ul>
                        {uploading ? <label className="button buttonIcon">Uploading File...</label> :
                            <>
                                <input style={{ display: 'none' }} className={styles.inputFile} id='list' name='list' type='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onChange={onSelectFile} />
                                <label className="button buttonIcon" htmlFor='list'><Upload size='20' style={{ marginRight: '5px' }} />Select File</label>
                            </>
                        }
                        {uploadError && <p>Something went wrong</p>}
                    </div>
                    :
                    <>
                        <div className='pageHeader'>
                            <div className='pageHeaderMain u-m-top-2'>
                                <form className='basic-form__search' onSubmit={(e) => searchSubmit(e)}>
                                    <input className={cx('basic-form__search__input', ' basic-form__text-box')} placeholder="Find Candidate" type="text" name="search" value={searchTerm} onChange={(e) => setSearchTerm(e.currentTarget.value)} />
                                    <button type="submit" className='basic-form__search__btn'>{searchLoading ? <Loader size="16" /> : <Search size="16" />}</button>
                                </form>
                                <FilterMenus filters={filters} setFilters={setFilters} />
                            </div>
                            {/* LP: commented out add candidates from dashboard */}
                            {/* <div className={styles.dropdownHolder}>
                                <AddBtn action={() => setShowAddMenu(!showAddMenu)} />
                                {showAddMenu &&
                                    <>
                                        <ul className={styles.dropdown}>
                                            <li onClick={() => { setShowIndividual(true); setShowAddMenu(false) }}>Create New Candidate</li>
                                            <li onClick={() => { setShowUpload(true); setShowAddMenu(false) }}>Upload Excel File</li>
                                        </ul>
                                    </>
                                }
                            </div> */}
                        </div>
                        {searchResults ?
                            <>
                                <span className={styles.backLink} onClick={() => clearSearch()}><ArrowLeft style={{ marginRight: '10px' }} size="16" /> Back</span>
                                <h1>Search results for '{placeHolderTerm}': {searchResults.candidates.length}</h1>
                                {searchResults.candidates.length > 0 &&
                                    <ul className={styles.candidateHeader}>
                                        <li>Computer Skills Test</li>
                                        <li>Job Role Match</li>
                                    </ul>
                                }
                                <ul>
                                    {searchResults.candidates.map(candidate => {
                                        return <CandidateListItem key={`search-${candidate.id}`} candidate={candidate} />
                                    })}
                                </ul>
                            </>
                            :
                            <>
                                {candidates.length === 0 ? <p>You haven't added any candidates yet.</p> :
                                    <Fragment>
                                        {loadingFilter ? <p>Loading</p> : (
                                            <>
                                                <ul className={styles.candidateHeader}>
                                                    <li>Computer Skills Test</li>
                                                    <li>Job Role Match</li>
                                                </ul>
                                                <ul className='u-m-base-3'>
                                                    {candidates.map((candidate) => {
                                                        return <CandidateListItem key={`candidate-${candidate.id}`} candidate={candidate} />
                                                    })}
                                                </ul>
                                            </>
                                        )}
                                        {filterData.showBtn && (
                                            <button onClick={() => loadMoreCandidates({
                                                variables: {
                                                    id: enterpriseId,
                                                    offset: filterData.offset + PAGE_SIZE,
                                                    state: !isNaN(+filters.state) ? +filters.state : null,
                                                    externalState: !isNaN(+filters.externalState) ? +filters.externalState : null,
                                                }
                                            })} className='button u-m-base-3'>{loadingMore ? 'Loading' : 'Load more'}</button>
                                        )}
                                    </Fragment>
                                }
                            </>
                        }
                    </>
                }

                {showAddIndividual &&
                    <Modal closeModal={() => setShowIndividual(false)}>
                        <h2 className='u-m-base-2'>Create individual candidate</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="basic-form__group">
                                <input
                                    name="firstName"
                                    placeholder="First Name"
                                    className="basic-form__text-box"
                                    ref={register}
                                    type="text"
                                />
                            </div>
                            {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                            <div className="basic-form__group">
                                <input
                                    name="lastName"
                                    placeholder="Last Name"
                                    className="basic-form__text-box"
                                    ref={register}
                                    type="text"
                                />
                            </div>
                            {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                            <div className="basic-form__group">
                                <input
                                    name="email"
                                    placeholder="Email"
                                    className="basic-form__text-box"
                                    ref={register}
                                    type="text"
                                />
                            </div>
                            {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                            {formErrors.length !== 0 && <p>Something went wrong</p>}
                            <button type="submit" className="button">{createLoading ? 'Saving' : 'Create Candidate'}</button>
                        </form>
                    </Modal>
                }
            </Fragment>
        )
    }
    return null;
}
export default Dashboard;