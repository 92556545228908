import React, { useState, useContext, useEffect } from 'react';
import AddJobRole from './AddJobRole';
import GroupStats from './GroupStats';
import CandidateRoleMatch from '../../../../components/CandidateRoleMatch';
import CandidateRoleHeader from '../../../../components/CandidateRoleMatch/CandidateRoleHeader';
import { CANDIDATES_FILTER, getCandidates } from '../../../../services/utils';
import { SkillsetContext } from '../../../../services/user/SkillsetProvider';
import _ from 'lodash';

const RoleMatchGroup = ({ enterpriseId, group }) => {

    const [statFilter, setStatFilter] = useState(CANDIDATES_FILTER.all);

    const candidates = getCandidates(group.candidates, statFilter);

    const orderItems = items =>
        _.orderBy(items, [
            item => item.externalAssessment.jobScore?.overallMatchingScore || '',
            item => item.externalAssessment.startedAt || '',
        ], ['desc', 'desc']);

    const { skillsets } = useContext(SkillsetContext);


    if (skillsets && skillsets.length > 0) {
        return (
            <>
                {!group.externalAssessmentJobCode ? <AddJobRole group={group} enterpriseId={enterpriseId} /> : (
                    <>
                        <GroupStats statFilter={statFilter} setStatFilter={setStatFilter} group={group} />
                        <ul>
                            <CandidateRoleHeader skillsets={skillsets} job={group.job} />
                            {orderItems(candidates).concat().map(candidate => (
                                <CandidateRoleMatch key={`candidate_${candidate.id}`} skillsets={skillsets} candidate={candidate} />
                            ))}
                        </ul>
                    </>
                )}
            </>
        )
    }
    return null;
}

export default RoleMatchGroup;