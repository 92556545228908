import React, {useState} from 'react';
import { Link } from '@reach/router';
import moment from 'moment'
import { CheckCircle, ArrowRight, Clock, Check, X, Minus, Slash } from 'react-feather';
import { getPercScores, getModifier } from '../../../../services/utils';
import { ASSESSMENT_STATE, CORE_SKILLS } from '../../../../services/constants';
import cx from 'classnames';
import styles from './CandidateListItem.module.scss'

const CandidateListItem = ({ candidate }) => {

    const getIcon = (score, targetScore) => {
        if (score / targetScore < 0.7) {
            return <X size="16" />;
        }
        if (score / targetScore >= 0.8) {
            return <Check size="16" />;
        }
        return <Minus size="16" />;
    }

    const renderComputerScores = () => {
        if (candidate.assessment.state === ASSESSMENT_STATE.not_started) {
            return (
                <>
                    <span>Not started</span>
                    <Clock size="22" />
                </>
            )
        }
        if (candidate.assessment.state === ASSESSMENT_STATE.in_progress) {
            return (
                <>
                    <span>In Progress</span>
                    <ArrowRight size="22" />
                </>
            )
        }
        if (candidate.assessment.state === ASSESSMENT_STATE.completed) {
            return (
                <>
                    <span>{getPercScores(candidate.candidateScores.filter(score => CORE_SKILLS.includes(score.skillset)))}</span>
                    <CheckCircle size="20" />
                </>
            )
        }
        return null;
    }

    const renderJobScores = () => {
        if (candidate.externalAssessment.status === ASSESSMENT_STATE.not_started) {
            return (
                <>
                    <span>Not started</span>
                    <Clock size="22" />
                </>
            )
        }
        if (candidate.externalAssessment.status === ASSESSMENT_STATE.in_progress) {
            return (
                <>
                    <span>In Progress</span>
                    <ArrowRight size="22" />
                </>
            )
        }
        if (candidate.externalAssessment.status === ASSESSMENT_STATE.completed && candidate.externalAssessment.jobScore) {
            return (
                <>
                    <span>{Math.round(candidate.externalAssessment.jobScore.overallMatchingScore)}%</span>
                    <span className={styles[getModifier(candidate.externalAssessment.jobScore.overallMatchingScore, 100)]}>
                        {getIcon(candidate.externalAssessment.jobScore.overallMatchingScore, 100)}
                    </span>
                </>
            )
        }
        return null;
    }

    return (
        <li>
            <Link to={`/candidate/${candidate.id}`} className={styles.candidateLink}>
                <div className={styles.candidateLinkMain}>
                    <h4 className={styles.candidateLinkTitle}>{candidate.firstName} {candidate.lastName}</h4>
                    <span className={styles.candidateCreated}>Added: {moment(candidate.createdAt).format('MM/DD/YYYY')}</span>
                </div>
                <div className={cx(styles.cScore, { [styles.cScoreNone]: !candidate.assessment })}>
                    {candidate.assessment ? renderComputerScores() : <>-- <Slash /></>}
                </div>

                <div className={cx(styles.cScore, { [styles.cScoreNone]: !candidate.externalAssessment })}>
                    {candidate.externalAssessment ? renderJobScores() : <>-- <Slash /></>}
                </div>
            </Link>
        </li>
    )
}

export default CandidateListItem;