import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { EDIT_CANDIDATE } from '../../services/graphql';
import FormErrors from '../../../../components/FormErrors';

const EditCandidate = ({ candidate, closeEdit }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [editCandidate, { loading }] = useMutation(EDIT_CANDIDATE, {
        onCompleted: data => {
            if (data.editCandidate.errors && data.editCandidate.errors.length !== 0) {
                setFormErrors(data.editCandidate.errors);
                return;
            }
            if (data.editCandidate.candidate && data.editCandidate.candidate.id) {
              closeEdit();
            }
        },
    });

    const schema = Yup.object().shape({
        firstName: Yup.string()
            .required('Please enter a first name for the candidate.'),
        lastName: Yup.string()
            .required('Please enter a last name for the candidate.'),
        email: Yup.string().email()
            .required('Please enter a valid email address.'),
        search: Yup.string()
    });


    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { firstName, lastName, email } = values;
        editCandidate({ variables: { candidateId: candidate.id, firstName, lastName, email, } })
    }

    return (
        <div>
            <h2 className='u-m-base-3'>Edit Candidate</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group">
                    <label htmlFor="firstName" className='basic-form__text-label'>First Name</label>
                    <input
                        name="firstName"
                        placeholder="First Name"
                        className="basic-form__text-box"
                        ref={register}
                        defaultValue={candidate.firstName}
                        type="text"
                        id="firstName"
                    />
                </div>
                {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                <div className="basic-form__group">
                    <label htmlFor="lastName" className='basic-form__text-label'>Last Name</label>
                    <input
                        name="lastName"
                        placeholder="Last Name"
                        className="basic-form__text-box"
                        defaultValue={candidate.lastName}
                        ref={register}
                        type="text"
                        id='lastName'
                    />
                </div>
                {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                <div className="basic-form__group">
                    <label htmlFor="email" className='basic-form__text-label'>Email</label>
                    <input
                        name="email"
                        placeholder="Email"
                        className="basic-form__text-box"
                        defaultValue={candidate.email}
                        ref={register}
                        id='email'
                        type="text"
                    />
                </div>
                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <button type="submit" className="button">{loading ? 'Saving' : 'Save'}</button>
                <button type='button' className="button buttonText u-m-left-2" onClick={closeEdit}>Cancel</button>
            </form>
        </div>
    );
}

export default EditCandidate;