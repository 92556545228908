import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';

import { useMutation } from '@apollo/react-hooks';
import { navigate } from "@reach/router"

import { RESET_PASSWORD } from '../Scenes/SetPassword/services/graphql';
import FormErrors from '../../../components/FormErrors';

const ResetPassword = ({ closeModal, invalid_token }) => {
    const [formErrors, setFormErrors] = useState([]);

    const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
        onCompleted: data => {
            if (data.resetPassword.errors && data.resetPassword.errors.length !== 0) {
                setFormErrors(data.resetPassword.errors);
                return;
            }
            if (data.resetPassword.reset) {
                navigate('/reset_sent');
            }
        }
    });

    const schema = Yup.object().shape({
        email: Yup.string().email()
        .required('Please enter a valid email address.'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        resetPassword({
            variables: {
                email: values.email
            }
        })
    };

    return(
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className="light text-primary u-m-base-3">Reset password</h1>
                {invalid_token && <FormErrors errors={invalid_token} />}
                <div className="basic-form__group">
                    <input
                        name="email"
                        placeholder="Email address"
                        className="basic-form__text-box"
                        type="email"
                        ref={register}
                    />
                </div>
                {errors.email && <p className='basic-form__hint'>Please enter email</p>}

                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}

                <button disabled={loading} type="submit" className="button">{loading ? 'Loading...' : 'Send link'}</button>
                <button type="button" onClick={() => closeModal()} className="button buttonText">Cancel</button>
            </form>
        </Fragment>
    )
}

export default ResetPassword;
