import React, { useState, createContext } from 'react';

export const SkillsetContext = createContext();

export const SkillsetProvider = ({ children }) => {

    const [skillsets, setSkillsets] = useState(null);

    return (
        <SkillsetContext.Provider
            value={{
                skillsets,
                setSkillsets
            }}
        >
            {children}
        </SkillsetContext.Provider>
    )
}