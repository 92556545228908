import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_CANDIDATE } from '../../../Dashboard/services/graphql';
import { GET_GROUP } from '../../services/graphql';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const CreateCandidate = ({ groupId, enterpriseId, close }) => {

    const [formErrors, setFormErrors] = useState([]);


    const [createCandidate, { loading }] = useMutation(CREATE_CANDIDATE, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.createCandidate.errors && data.createCandidate.errors.length !== 0) {
                setFormErrors(data.createCandidate.errors);
                return;
            }
            if (data.createCandidate.candidate && data.createCandidate.candidate.id) {
                close();
            }
        },
        refetchQueries: () => [
            {
                query: GET_GROUP,
                variables: {
                    id: groupId
                },
            }
        ]
    });


    const schema = Yup.object().shape({
        firstName: Yup.string()
            .required('Please enter a first name for the candidate.'),
        lastName: Yup.string()
            .required('Please enter a last name for the candidate.'),
        email: Yup.string().email()
            .required('Please enter a valid email address.'),
        search: Yup.string()
    });


    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { firstName, lastName, email } = values;
        createCandidate({ variables: { enterpriseId, firstName, lastName, email, groupId } })
    }


    return (
        <>
            <h3 className='u-m-base-3'>Create new candidate</h3>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group">
                    <input
                        name="firstName"
                        placeholder="First Name"
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="lastName"
                        placeholder="Last Name"
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="email"
                        placeholder="Email"
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>
                {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                {formErrors.length !== 0 && <p>Something went wrong</p>}
                <button type="submit" className="button">{loading ? 'Saving' : 'Create Candidate'}</button>
                <button type="button" className="button buttonText" onClick={() => close()}>Cancel</button>
            </form>
        </>
    )
}

export default CreateCandidate;