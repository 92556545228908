// Queries and mutations for the Login module
import { gql } from 'apollo-boost';

export const LOGIN = gql`
mutation logIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
        user {
            id
            email
            firstName
            lastName
            name
            enterprise {
                id
            }
        }
        token
        errors
    }
}
`;