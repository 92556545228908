import React, { useContext } from 'react';
import { ExternalLink } from 'react-feather';
import styles from '../../Candidate.module.scss';
import CandidateRoleMatch from '../../../../components/CandidateRoleMatch';
import CandidateRoleHeader from '../../../../components/CandidateRoleMatch/CandidateRoleHeader';
import { SkillsetContext } from '../../../../services/user/SkillsetProvider';

const RoleMatchResult = ({ candidate }) => {

    const { skillsets } = useContext(SkillsetContext);

    const hostname = window?.location?.hostname;
    const link = hostname && (hostname === 'localhost' ? 'http://localhost:3000' : hostname.includes('staging') ? 'https://candidates-republic-staging.herokuapp.com' : 'https://candidates.futurehorizonsgroup.com')

    if (skillsets && skillsets.length) {
        return (
            <>
                <div className={styles.assessmentHeader}>
                    <h3 className={styles.assessmentHeaderTitle}>{candidate.job.name}</h3>
                    <div className={styles.assessmentHeaderSide}>
                        Access code: <b className='u-m-left-1'>{candidate.externalAssessment.accessCode}</b>
                        {link && <a target="blank" className={styles.userLink} rel="noopener noreferrer" href={link}><ExternalLink size="18" /></a>}
                    </div>
                </div>
                <hr className='separator' />
                <ul>
                    <CandidateRoleHeader skillsets={skillsets} job={candidate.job} />
                    <CandidateRoleMatch skillsets={skillsets} candidate={candidate} job={candidate.job} />
                </ul>
            </>
        )
    }
    return null;
}

export default RoleMatchResult;