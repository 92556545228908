import { gql } from 'apollo-boost'

export const GET_ADMINS = gql`
query enterprise($id: ID!) {
    enterprise(id: $id) {
        id
        admins {
            id
            email
            active
            name
        }
    }
}`;

export const CREATE_ADMIN = gql`
mutation createAdmin($enterpriseId: ID!, $firstName: String!, $lastName: String! $email: String!) {
    createAdmin(enterpriseId: $enterpriseId, firstName: $firstName, lastName: $lastName, email: $email) {
        user {
            id
            email
        }
        errors
    }
}
`;


export const DELETE_ADMIN = gql`
    mutation deleteAdmin($email: String!, $enterpriseId: ID!) {
        deleteAdmin(email: $email, enterpriseId: $enterpriseId) {
            deleted
            errors
        }
    }

`

export const RESEND_INVITE = gql`
    mutation resendInvite($email: String!) {
        resendInvite(email: $email) {
            reset
            errors
        }
    }

`