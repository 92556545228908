import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_GROUP, GET_GROUPS, GET_JOBS_LIST } from '../../services/graphql';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const EditGroup = ({ group, close, enterpriseId, selectedTab, tabs }) => {

    const [formErrors, setFormErrors] = useState([]);

    const { data, loading, error } = useQuery(GET_JOBS_LIST);


    const [updateGroup, { loading: updatingGroup }] = useMutation(UPDATE_GROUP, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.updateGroup.errors && data.updateGroup.errors.length !== 0) {
                setFormErrors(data.create.errors);
                return;
            }
            if (data.updateGroup.group && data.updateGroup.group.id) {
                close()
            }
        },
        refetchQueries:[
            {
                query: GET_GROUPS,
                variables: {
                    enterpriseId,
                    ...(selectedTab !== tabs.all && { active: selectedTab === tabs.active })
                },
            }
        ]
    });

    const schema = Yup.object().shape({
        name: Yup.string().required('Please enter a name for the group.'),
        externalAssessmentJobCode: Yup.string().when('needsExternalAssessment', (value, schema) => {
            if (value === true) {
                return schema
                    .required('Please select role!')
            }
            return schema.notRequired()
        }),
        active: Yup.bool().typeError('Please select a value').required('Please select status')
    });

    const { register, handleSubmit, errors, watch } = useForm({
        validationSchema: schema,
        defaultValues: {
            name: group.name,
            needsLocalAssessment: group.needsLocalAssessment,
            needsExternalAssessment: group.needsExternalAssessment,
            externalAssessmentJobCode: group.externalAssessmentJobCode,
            active: group.active
        }
    });

    const watchRole = watch('needsExternalAssessment', group.needsExternalAssessment);


    const onSubmit = values => {
        const { name, needsLocalAssessment, needsExternalAssessment, externalAssessmentJobCode, active } = values;
        updateGroup({
            variables: {
                enterpriseId,
                groupId: group.id,
                name,
                needsLocalAssessment,
                needsExternalAssessment,
                externalAssessmentJobCode,
                active
            }
        })
    }

    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) return <p>Loading</p>

    if (data && data.externalJobsList) {

        return (
            <>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3 className='u-m-base-3'>Edit Group</h3>
                    <div className="basic-form__group">
                        <label htmlFor="name" className='basic-form__label  u-m-base-1'>Group name</label>
                        <input
                            name="name"
                            placeholder="Name"
                            className="basic-form__text-box"
                            ref={register}
                            type="text"
                        />
                    </div>
                    {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}

                    <div className="basic-form__group">
                        <label htmlFor="active" className='basic-form__label u-m-base-1'>Group Status</label>
                        <select
                            name="active"
                            className="basic-form__text-select"
                            ref={register}

                        >
                            <option value="">Select status</option>
                            <option value={true}>Active</option>
                            <option value={false}>Closed</option>
                        </select>
                    </div>
                    {errors.active && <p className="basic-form__hint">{errors.active.message}</p>}

                    <div className="u-m-top-2 u-display-flex basic-form__group basic-form__group--check">
                        <p className='u-m-right-3 u-bold'>Computer Skills Test</p>
                        <input
                            type="checkbox"
                            name="needsLocalAssessment"
                            ref={register}
                            className="switchToggle"
                            id="needsLocalAssessment"
                            disabled={group.needsLocalAssessment}
                        />
                        <label className="switchLabel" htmlFor="needsLocalAssessment">toggle</label>
                    </div>

                    <div className="u-m-top-2 u-display-flex basic-form__group basic-form__group--check">
                        <p className='u-m-right-3 u-bold'>Job Role Assessment</p>
                        <input
                            type="checkbox"
                            name="needsExternalAssessment"
                            ref={register}
                            className="switchToggle"
                            id="needsExternalAssessment"
                            disabled={group.needsExternalAssessment}
                        />
                        <label className="switchLabel" htmlFor="needsExternalAssessment">toggle</label>
                    </div>
                    {watchRole && (
                        <>
                            <div className="basic-form__group">
                                <select disabled={group.externalAssessmentJobCode} className="basic-form__text-select" ref={register} name="externalAssessmentJobCode">
                                    <option value=''>Please Select a Job Role</option>
                                    {data.externalJobsList.map(job => (
                                        <option key={`job-${job.id}`} value={job.code}>{job.name}</option>
                                    ))}
                                </select>
                            </div>
                            {errors.externalAssessmentJobCode && <p className="basic-form__hint">{errors.externalAssessmentJobCode.message}</p>}
                        </>
                    )}
                    {formErrors.length !== 0 && <p>Something went wrong</p>}
                    <div className="u-m-top-3">
                        <button type="submit" disabled={updatingGroup} className="button">{updatingGroup ? 'Saving...' : 'Update'}</button>
                        <button type="button" onClick={close} className="button buttonText">Cancel</button>
                    </div>
                </form>
            </>
        )
    }
    return null;
}


export default EditGroup;