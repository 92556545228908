// Queries and mutations for the Home module
import { gql } from 'apollo-boost';
import { adminQuery } from '../../../services/constants';

export const LOGGED_IN = gql`
    {
        loggedIn @client
        error @client
    }
`;

export const CURRENT_USER_QUERY = gql`
query currentUser {
    currentUser {
       ${adminQuery}
    }
}
`;

// Has the client thrown an error
export const ERROR = gql`
    {
        error @client
    }
`;

// Has the user been 401 logged out
export const FORCED_LOGOUT = gql`
    {
        forcedLogout @client
    }
`;


export const GET_SKILLSETS_QUERY = gql`
query skillsets {
        skillsets {
            id
            name
            maximumScore
        }
    }
`;