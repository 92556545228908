import  { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_SKILLSETS_QUERY } from "../services/graphql";
import { SkillsetContext } from "../../../services/user/SkillsetProvider";

const SkillsetQuery = () => {

    const { setSkillsets } = useContext(SkillsetContext);
    const { data } = useQuery(GET_SKILLSETS_QUERY);

    useEffect(() => {
        if (data && data.skillsets) {
            setSkillsets(data.skillsets);
        }
    }, [data]);

    return null;
}

export default SkillsetQuery;