
import { gql } from 'apollo-boost';

export const GET_GROUPS = gql`
query groups($enterpriseId: ID!, $active: Boolean) {
    groups(enterpriseId: $enterpriseId, active: $active) {
        id
        name
        active
        externalAssessmentJobCode
        needsExternalAssessment
        needsLocalAssessment
        job {
            id
            name
        }
    }
}`;

export const CREATE_GROUP = gql`
mutation createGroup($enterpriseId: ID!, $name: String!, $needsExternalAssessment: Boolean!, $needsLocalAssessment: Boolean!, $externalAssessmentJobCode: String) {
    createGroup(enterpriseId: $enterpriseId, name: $name, needsExternalAssessment: $needsExternalAssessment, needsLocalAssessment: $needsLocalAssessment, externalAssessmentJobCode: $externalAssessmentJobCode) {
        group {
            id
        }
        errors
    }
}
`;

export const UPDATE_GROUP = gql`
mutation updateGroup($enterpriseId: ID!, $groupId: ID!, $name: String!, $needsExternalAssessment: Boolean!, $needsLocalAssessment: Boolean!, $externalAssessmentJobCode: String, $active: Boolean!) {
    updateGroup(enterpriseId: $enterpriseId, groupId: $groupId, name: $name, needsExternalAssessment: $needsExternalAssessment, needsLocalAssessment: $needsLocalAssessment, externalAssessmentJobCode: $externalAssessmentJobCode, active: $active) {
        group {
            id
            externalAssessmentJobCode
            needsExternalAssessment
            needsLocalAssessment
            active
            job {
                id
                name
            }
        }
        errors
    }
}
`;

export const GET_JOBS_LIST = gql`
query externalJobsList {
    externalJobsList {
        id
        code
        name
    }
}
`;

