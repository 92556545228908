import React, { useState } from 'react'
import SkillAnswers from './SkillAnswers';
import { ChevronDown, ChevronLeft } from 'react-feather';
import styles from '../../Candidate.module.scss';
import { CORE_SKILLS } from '../../../../services/constants';

const AnswersContent = ({ candidate, assessmentAnswers }) => {

    const [showCoreSkills, setShowCoreSkills] = useState(true);
    const [showOthers, setShowOthers] = useState(false);

    return (
        <div className='u-m-base-3'>
            <div className={styles.skillGroupsTitle} onClick={() => setShowCoreSkills(!showCoreSkills)}>
                <h1>Core skills</h1>
                {showCoreSkills ? <ChevronDown size="28" /> : <ChevronLeft size="28" />}
            </div>
            {showCoreSkills && (
                <ul>
                    {candidate.candidateScores.filter(score => CORE_SKILLS.includes(score.skillset)).map(score => {
                        return (
                            <SkillAnswers
                                key={`skillset-${score.id}`}
                                score={score}
                                skillAnswers={assessmentAnswers.filter(answer => answer.question.skillset === score.skillset)}
                            />
                        )
                    })}
                </ul>
            )}

            <div className={styles.skillGroupsTitle} onClick={() => setShowOthers(!showOthers)}>
                <h1>Other skills</h1>
                {showOthers ? <ChevronDown size="28" /> : <ChevronLeft size="28" />}
            </div>
            {showOthers && (
                <ul>
                    {candidate.candidateScores.filter(score => !CORE_SKILLS.includes(score.skillset)).map(score => {
                        return (
                            <SkillAnswers
                                key={`skillset-${score.id}`}
                                score={score}
                                skillAnswers={assessmentAnswers.filter(answer => answer.question.skillset === score.skillset)}
                            />
                        )
                    })}
                </ul>
            )}
        </div>
    )

}

export default AnswersContent