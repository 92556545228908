import { gql } from 'apollo-boost'

export const GET_CANDIDATE = gql`
query candidate($id: ID!) {
    candidate(id: $id) {
        id
        firstName
        lastName
        email
        createdAt
        needsSync
        assessment {
            id
            accessCode
            state
        }
        candidateScores {
            id
            score
            skillset
            totalAnswers
        }
        job {
            id
            name
            requiredScores {
                id
                skillset
                requiredScore
            }
        }
        externalAssessment {
            id
            accessCode
            finishedAt
            startedAt
            status
            jobScore {
                id
                overallMatchingScore
                details {
                    id
                    actualScore
                    requiredScore
                    skillset
                    maximumScore
                }
            }
            candidateExternalScores {
                id
                overallMatchingScore
                jobName
            }
        }
    }
}`;

export const EDIT_CANDIDATE = gql`
mutation editCandidate($candidateId: ID!, $firstName: String!, $lastName: String!, $email: String!) {
    editCandidate(candidateId: $candidateId, firstName: $firstName, lastName: $lastName, email: $email) {
        candidate {
            id
            firstName
            lastName
            email
            createdAt
            assessment {
                id
                accessCode
                state
            }
            candidateScores {
                id
                score
                skillset
                totalAnswers
            }
        }
        errors
    }
}
`;

export const CANDIDATE_SYNC = gql`
mutation candidateSync($candidateId: ID!) {
    candidateSync(candidateId: $candidateId) {
        candidate {
            id
            needsSync
        }
        errors
    }
}
`;

export const RESEND_CANDIDATE_INVITE = gql`
mutation resendCandidateInvite($candidateId: ID!) {
    resendCandidateInvite(candidateId: $candidateId) {
        errors
        reset
    }
}
`;


export const GET_ANSWERS = gql`
query assessmentAnswers($assessmentId: ID!) {
    assessmentAnswers(assessmentId: $assessmentId) {
        id
        attempts
        duration
        passed
        question {
            id
            description
            skillset
        }
    }
}
`