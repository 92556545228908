import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router'
import { UPDATE_PASSWORD } from './services/graphql';
import Modal from '../../../../components/Modal';
import ResetPassword from '../../components/ResetPassword';
import styles from '../../Login.module.scss';

const SetPassword = ({ token, '*': reset }) => {

    const [resetModal, setResetModal] = useState();

    const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.updatePassword.errors && data.updatePassword.errors.length !== 0) {
                setResetModal(data.updatePassword.errors);
                return;
            }
            if (data.updatePassword.user && data.updatePassword.user.id) {
                navigate('/', { state: { password_updated: true } });
            }
        },
    });

    const schema = Yup.object().shape({
        password: Yup.string()
            .required('Please enter a password'),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });


    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { password, passwordConfirmation } = values;
        updatePassword({
            variables: {
                token,
                password,
                passwordConfirmation
            }
        });
    }

    return (
        <div className={styles.col}>

            <h1 className='u-m-base-3'>{!!reset ? 'Reset password' : 'Set a password'}</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="basic-form__group">
                    <input
                        name="password"
                        placeholder={'Password'}
                        className="basic-form__text-box"
                        type="password"
                        ref={register}
                    />
                </div>
                {errors.password && <p className="basic-form__hint">{errors.password.message}</p>}
                <div className="basic-form__group">
                    <input
                        name="passwordConfirmation"
                        placeholder={'Confirm Password'}
                        className="basic-form__text-box"
                        type="password"
                        ref={register}
                    />
                </div>
                {errors.passwordConfirmation && <p className="basic-form__hint">{errors.passwordConfirmation.message}</p>}
                <button type="submit" className="button">{loading ? 'Loading' : 'Set Password'}</button>
            </form>

            {
                resetModal && (
                    <Modal closeModal={() => setResetModal(false)}>
                        <ResetPassword invalid_token={resetModal} closeModal={() => setResetModal(false)} />
                    </Modal>
                )
            }
        </div>
    )
}

export default SetPassword