import React from 'react';
import { Link } from '@reach/router';
import { Settings, CheckCircle, Slash } from 'react-feather';
import styles from './GroupItem.module.scss';

const GroupItem = ({ group, setShowEditGrop }) => {

    return (
        <li className={styles.group}>
            <Link to={`/group/${group.id}`} className={styles.groupLink}>
                <h4 className={styles.groupTitle}> {group.name}</h4>
                <ul className={styles.groupAssessments}>
                    {
                        group.needsLocalAssessment ?
                            <li><CheckCircle size={18} /> Computer Skills</li> :
                            <li className={styles.groupAssessmentsEmpty}><Slash size={16} /> No Computer Skills</li>
                    }
                    {group.job ?
                        <li><CheckCircle size={18} /> {group.job.name}</li> :
                        <li className={styles.groupAssessmentsEmpty}><Slash size={16} /> No Role match</li>
                    }
                </ul>
            </Link>
            <span className='u-m-right-2'>{group.active ? 'Active' : 'Closed'}</span>
            <button className='btn-reset' onClick={() => setShowEditGrop(group)}><Settings /></button>
        </li>
    )
}

export default GroupItem;