import React from 'react';
import ActionCable from 'actioncable';
import {
    ActionCableConsumer,
    ActionCableProvider,
} from '@thrash-industries/react-actioncable-provider';

const GroupActionCable = ({ onReceived, groupId }) => {
    const hostname = window && window.location && window.location.hostname;

    const cable = ActionCable.createConsumer(
        hostname !== 'localhost' && !hostname.includes('staging')
            ? 'wss://api.cuttingedge.app/cable'
            : 'wss://staging-api.cuttingedge.app/cable'
    );
    return (
        <ActionCableProvider cable={cable}>
            <ActionCableConsumer
                channel={{
                    channel: "GroupNotificationsChannel",
                    id: `${groupId}`
                }}
                onReceived={(message) => {
                    onReceived(message);
                }}
            ></ActionCableConsumer>
        </ActionCableProvider>
    );
};

export default GroupActionCable;

