import React from 'react';
import styles from './CandidateRoleMatch.module.scss';
import cx from 'classnames';

const CandidateRoleHeader = ({ job, skillsets }) => {

    const getMaxScore = (id) => {
        return skillsets.filter(el => el.id === id)[0].maximumScore;
    }

    return (
        <li className={styles.candidateItem}>
            <div className={styles.candidateItemInner}>
                <div className={styles.candidateItemMain}>&nbsp;</div>
                <div className={styles.candidateItemScoreWrapper}>

                    <div className={cx(styles.candidateItemScore, styles.candidateItemHeaderAll)}>
                        Ideal
                        <div style={{ fontSize: '20px' }}>80%</div>
                    </div>
                    {
                        job && job.requiredScores && job.requiredScores.map(rs => {
                            const { id, skillset, requiredScore } = rs;
                            return (
                                <div key={`rs_${id}`} style={{ fontSize: '11px' }} className={styles.candidateItemScore}>
                                    <p>{skillset}</p>
                                    <div className='u-text-primary'>
                                        Ideal {requiredScore} | Max {getMaxScore(id)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </li>
    )
}

export default CandidateRoleHeader;
