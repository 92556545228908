import React, { useContext, useState } from 'react';
import { AuthContext } from '../../services/user/AuthProvider';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useQuery, useMutation } from '@apollo/react-hooks';
import AddBtn from '../../components/AddBtn'
import Modal from '../../components/Modal'

import { CREATE_ADMIN, GET_ADMINS } from './services/graphql';
import AdminItem from './components/AdminItem';

const Admins = () => {

    const [showAddAdmin, setShowAddAdmin] = useState(false);
    const [formErrors, setFormErrors] = useState([])
    const { currentUser } = useContext(AuthContext);
    const enterpriseId = currentUser.enterprise.id;

    const { data, error, loading } = useQuery(GET_ADMINS, {
        variables: {
            id: enterpriseId,
        },
    });
    const [createAdmin, { loading: createLoading }] = useMutation(CREATE_ADMIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.createAdmin.errors && data.createAdmin.errors.length !== 0) {
                setFormErrors(data.createAdmin.errors);
                return;
            }
            if (data.createAdmin.user && data.createAdmin.user.id) {
                setShowAddAdmin(false)
            }
        },
        refetchQueries: () => [
            {
                query: GET_ADMINS,
                variables: {
                    id: enterpriseId
                },
            }
        ]
    });


    const schema = Yup.object().shape({
        firstName: Yup.string()
            .required('Please enter a first name for the admin.'),
        lastName: Yup.string()
            .required('Please enter a last name for the admin.'),
        email: Yup.string().email()
            .required('Please enter a valid email address.'),
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { firstName, lastName, email } = values;
        createAdmin({ variables: { enterpriseId, firstName, lastName, email, } })
    }

    if (error) {
        return <p>Something went wrong&hellip;</p>
    }
    if (loading) {
        return <p>Loading&hellip;</p>

    }
    if (data) {
        return (
            <>
                <div className='pageHeader'>
                    <h1 className='u-m-right-2'>Administrators</h1>
                    <AddBtn action={() => setShowAddAdmin(!showAddAdmin)} />
                </div>
                <ul>
                    {data.enterprise.admins.map(admin => {
                        if (admin.id === currentUser.id) {
                            return null
                        }
                        return <AdminItem key={`admin-${admin.id}`} currentUser={currentUser} enterpriseId={enterpriseId} admin={admin} />
                    })}
                </ul>

                {
                    showAddAdmin &&
                    <Modal closeModal={() => setShowAddAdmin(false)}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="basic-form__group">
                                <input
                                    name="firstName"
                                    placeholder="First Name"
                                    className="basic-form__text-box"
                                    ref={register}
                                    type="text"
                                />
                            </div>
                            {errors.firstName && <p className="basic-form__hint">{errors.firstName.message}</p>}
                            <div className="basic-form__group">
                                <input
                                    name="lastName"
                                    placeholder="Last Name"
                                    className="basic-form__text-box"
                                    ref={register}
                                    type="text"
                                />
                            </div>
                            {errors.lastName && <p className="basic-form__hint">{errors.lastName.message}</p>}
                            <div className="basic-form__group">
                                <input
                                    name="email"
                                    placeholder="Email"
                                    className="basic-form__text-box"
                                    ref={register}
                                    type="text"
                                />
                            </div>
                            {errors.email && <p className="basic-form__hint">{errors.email.message}</p>}
                            {formErrors.length !== 0 && <p>Something went wrong</p>}
                            <button type="submit" className="button">{createLoading ? 'Saving' : 'Create Admin'}</button>
                        </form>
                    </Modal>
                }
            </>
        )
    }
    return null
}

export default Admins;