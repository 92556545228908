import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_GROUP, GET_JOBS_LIST } from '../../services/graphql';
import { navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const CreateGroup = ({ close, enterpriseId }) => {

    const [formErrors, setFormErrors] = useState([]);

    const schema = Yup.object().shape({
        name: Yup.string().required('Please enter a name for the group.'),
        externalAssessmentJobCode: Yup.string().when('needsExternalAssessment', (value, schema) => {
            if (value === true) {
                return schema
                    .required('Please select role!')
            }
            return schema.notRequired()
        }),
        needsExternalAssessment: Yup.boolean(),
        needsLocalAssessment: Yup.boolean(),
    }).test(
        'myCustomTest', //test at least one assessment chosen
        null,
        (obj) => {
            if (obj.needsExternalAssessment || obj.needsLocalAssessment) {
                return true;
            }
            return new Yup.ValidationError(
                'Please check one assessment type',
                null,
                'assessmentTypeChoice'
            );
        }
    );

    const { data, loading, error } = useQuery(GET_JOBS_LIST);

    const { register, handleSubmit, errors, watch } = useForm({
        validationSchema: schema,

    });

    const watchRole = watch('needsExternalAssessment', false);

    const [createGroup, { loading: creatingGroup }] = useMutation(CREATE_GROUP, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.createGroup.errors && data.createGroup.errors.length !== 0) {
                setFormErrors(data.create.errors);
                return;
            }
            if (data.createGroup.group && data.createGroup.group.id) {
                close();
                navigate(`/group/${data.createGroup.group.id}`);

            }
        }
    });



    const onSubmit = values => {
        const { name, needsLocalAssessment, externalAssessmentJobCode, needsExternalAssessment } = values;
        createGroup({
            variables: {
                name,
                enterpriseId,
                externalAssessmentJobCode,
                needsExternalAssessment,
                needsLocalAssessment
            }
        });
    }

    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) return <p>Loading</p>

    if (data && data.externalJobsList) {

        return (
            <>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3 className='u-m-base-3'>Create Group/Class</h3>
                    <div className="basic-form__group">
                        <label htmlFor="name" className='basic-form__label'>Group name</label>
                        <input
                            name="name"
                            placeholder="Name"
                            className="basic-form__text-box"
                            ref={register}
                            type="text"
                        />
                    </div>
                    {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}

                    <div className="u-m-top-2 u-display-flex basic-form__group basic-form__group--check">
                        <p className='u-m-right-3 u-bold'>Computer Skills Test</p>
                        <input
                            type="checkbox"
                            name="needsLocalAssessment"
                            ref={register}
                            className="switchToggle"
                            id="needsLocalAssessment"
                        />
                        <label className="switchLabel" htmlFor="needsLocalAssessment">toggle</label>
                    </div>

                    <div className="u-m-top-2 u-display-flex basic-form__group basic-form__group--check">
                        <p className='u-m-right-3 u-bold'>Job Role Assessment</p>
                        <input
                            type="checkbox"
                            name="needsExternalAssessment"
                            ref={register}
                            className="switchToggle"
                            id="needsExternalAssessment"

                        />
                        <label className="switchLabel" htmlFor="needsExternalAssessment">toggle</label>
                    </div>
                    {watchRole && (
                        <>
                            <div className="basic-form__group">
                                <select className="basic-form__text-select" ref={register} name="externalAssessmentJobCode">
                                    <option value=''>Please Select a Job Role</option>
                                    {data.externalJobsList.map(job => (
                                        <option key={`job-${job.id}`} value={job.code}>{job.name}</option>
                                    ))}
                                </select>
                            </div>
                            {errors.externalAssessmentJobCode && <p className="basic-form__hint">{errors.externalAssessmentJobCode.message}</p>}
                        </>
                    )}
                    {errors.assessmentTypeChoice && <p className="basic-form__hint u-m-top-2">{errors.assessmentTypeChoice.message}</p>}

                    {formErrors.length !== 0 && <p>Something went wrong</p>}
                    <div className="u-m-top-3">
                        <button type="submit" disabled={creatingGroup} className="button">{creatingGroup ? 'Saving...' : 'Create'}</button>
                        <button type="button" onClick={close} className="button buttonText">Cancel</button>
                    </div>
                </form>
            </>
        )
    }
    return null;
}

export default CreateGroup;