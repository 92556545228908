import React, { useState } from 'react';

import { X, ChevronDown } from 'react-feather';
import { ASSESSMENT_STATE } from '../../services/constants';

const FilterMenus = ({ visibleSkillFilter, visibleJobFilter, filterData, setFilterData, jobFilterData, setJobFilterData }) => {

    const [showSkillFilter, setShowSkillFilter] = useState(false);
    const [showJobFilter, setShowJobFilter] = useState(false);

    const getFilterLabel = filter => {
        switch (filter) {
            case ASSESSMENT_STATE.completed:
                return 'Completed';
            case ASSESSMENT_STATE.in_progress:
                return 'In Progress';
            case ASSESSMENT_STATE.not_started:
                return 'Not started';
            default:
                return 'All';
        }
    }

    return (
        <>
            {visibleSkillFilter &&
                <div className='filterOpts'>
                    <p>Skills Test</p>
                    {filterData !== null && (
                        <span className='filterOptsLabel'>
                            {getFilterLabel(filterData)}
                            <X onClick={() => setFilterData(null)} />
                        </span>
                    )}
                    {filterData === null && <button className='filterOptsBtn' onClick={() => setShowSkillFilter(!showSkillFilter)}>Any Status  <ChevronDown /></button>}
                    {showSkillFilter && (
                        <div className='filterOptsMenu'>
                            <ul>
                                <li onClick={() => { setFilterData(null); setShowSkillFilter(false) }}>Any Status</li>
                                <li onClick={() => { setFilterData(ASSESSMENT_STATE.completed); setShowSkillFilter(false) }}>Completed</li>
                                <li onClick={() => { setFilterData(ASSESSMENT_STATE.in_progress); setShowSkillFilter(false) }}>In progress</li>
                                <li onClick={() => { setFilterData(ASSESSMENT_STATE.not_started); setShowSkillFilter(false) }}>Not started</li>
                            </ul>
                        </div>
                    )}
                </div>
            }

            {visibleJobFilter &&
                <div className='filterOpts'>
                    <p>Role Match</p>
                    {jobFilterData !== null && (
                        <span className='filterOptsLabel'>
                            {getFilterLabel(jobFilterData)}
                            <X onClick={() => setJobFilterData(null)} />
                        </span>
                    )}
                    {jobFilterData === null && <button className='filterOptsBtn' onClick={() => setShowJobFilter(!showJobFilter)}>Any Status <ChevronDown /></button>}
                    {showJobFilter && (
                        <div className='filterOptsMenu'>
                            <ul>
                                <li onClick={() => { setJobFilterData(null); setShowJobFilter(false) }}>Any status</li>
                                <li onClick={() => { setJobFilterData(ASSESSMENT_STATE.completed); setShowJobFilter(false) }}>Completed</li>
                                <li onClick={() => { setJobFilterData(ASSESSMENT_STATE.in_progress); setShowJobFilter(false) }}>In progress</li>
                                <li onClick={() => { setJobFilterData(ASSESSMENT_STATE.not_started); setShowJobFilter(false) }}>Not started</li>
                            </ul>
                        </div>
                    )}
                </div>
            }
        </>
    )
}

export default FilterMenus;