
import { gql } from 'apollo-boost';

const groupQuery = `
id
name
externalAssessmentJobCode
needsExternalAssessment
needsLocalAssessment
active
needsSync
job {
    id
    name
    requiredScores {
        id
        skillset
        requiredScore
    }
}
candidates(state: $state) {
    id
    email
    firstName
    lastName
    createdAt
    job {
        id
    }
    assessment {
        id
        state
    }
    candidateScores {
        id
        score
        totalAnswers
        skillset
    }
    externalAssessment {
        id
        accessCode
        status
        finishedAt
        startedAt
        jobScore {
            id
            overallMatchingScore
            details {
                id
                actualScore
                maximumScore
                requiredScore
                skillset
            }
        }
        candidateExternalScores {
            id
            overallMatchingScore
            jobName
        }
    }
}
`;


export const GET_GROUP = gql`
query group($id: ID!, $state: Int) {
    group(id: $id) {
        ${groupQuery}
    }
}`;

export const SEARCH_CANDIDATES = gql`
    query searchCandidates($id: ID!, $query: String) {
    group(id: $id) {
        id
        candidates(query: $query) {
            id
            firstName
            lastName
            email
            createdAt
            assessment {
                id
                state
            }
            candidateScores {
                id
                score
                totalAnswers
                skillset
            }
            externalAssessment {
                id
                status
                jobScore {
                    id
                    overallMatchingScore
                }
            }
        }
    }
}`;

export const GROUP_SYNC = gql`
mutation groupSync($groupId: ID!) {
    groupSync(groupId: $groupId) {
        errors
        group {
            id
            needsSync
        }
    }
}
`;