import React, { Fragment, useState } from 'react';
import { GET_ADMINS, DELETE_ADMIN, RESEND_INVITE } from '../../services/graphql';

import { useMutation } from '@apollo/react-hooks';
import { XCircle } from 'react-feather';
import Modal from '../../../../components/Modal';
import FormErrors from '../../../../components/FormErrors';

import styles from './AdminItem.module.scss'

const AdminItem = ({ admin, enterpriseId }) => {

    const [deleteErrors, setDeleteErrors] = useState([]);
    const [deleteAdmin, setDeleteAdmin] = useState(null);
    const [formErrors, setFormErrors] = useState([])


    const [deleteAdminMutation, { loading: deleteLoading }] = useMutation(DELETE_ADMIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.deleteAdmin.errors && data.deleteAdmin.errors.length !== 0) {
                setDeleteErrors(data.deleteAdmin.errors)
                return
            }
            if (data.deleteAdmin.deleted) {
                setDeleteAdmin(null);
            }
        },
        refetchQueries: () => [
            {
                query: GET_ADMINS,
                variables: {
                    id: enterpriseId
                },
            }
        ]
    });

    const [resendInvite, { loading: resending }] = useMutation(RESEND_INVITE, {
        onCompleted: data => {
            if (data.resendInvite.errors && data.resendInvite.errors.length !== 0) {
                setFormErrors(data.resendInvite.errors);
                return;
            }
            if (data.resendInvite && data.resendInvite.reset) {
                //done
            }
        },
    })

    return (
        <Fragment>
            <li >
                <div className={styles.admin}>
                    <div style={{ marginRight: 'auto' }}>
                        <h4 className={styles.adminTitle}>{admin.name}</h4>
                        <p>{admin.email}</p>
                        {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                    </div>
                    {!admin.active &&
                        <button
                            onClick={() => resendInvite({
                                variables: {
                                    email: admin.email
                                }
                            })}
                            className='button buttonText u-m-right-1'
                        >{resending ? 'sending...' : 'resend invite'}</button>
                    }
                    <XCircle stroke="#F84949" className={styles.adminDelete} onClick={() => { setDeleteAdmin(admin) }} />
                </div>
            </li>
            {
                deleteAdmin &&
                <Modal closeModal={() => { setDeleteAdmin(null) }}>
                    <p>Are you sure you want to delete this admin?</p>
                    <p className="u-bold u-m-base-2">{deleteAdmin.email}</p>
                    <button className="button" onClick={() => deleteAdminMutation({
                        variables: {
                            email: deleteAdmin.email,
                            enterpriseId
                        }
                    })}>{deleteLoading ? 'Deleting... ' : 'Yes'}</button>
                    <button className="button buttonCancel u-m-left-2" onClick={() => { setDeleteAdmin(null) }}>No</button>
                    {deleteErrors.length > 0 && <FormErrors errors={deleteErrors} />}
                </Modal>
            }
        </Fragment>

    )
}
export default AdminItem;