import React from 'react';
import { Check, Minus, X } from 'react-feather';
import moment from 'moment'

import cx from 'classnames';
import styles from './CandidateRoleMatch.module.scss';
import { getModifier } from '../../services/utils';

const CandidateMatchModal = ({ candidate }) => {

    const matchingScore = candidate.externalAssessment.jobScore;
    const otherMatchingScores = candidate.externalAssessment.candidateExternalScores;

    let totalScore = 0;
    let totalIdeal = 0;

    matchingScore && matchingScore.details.forEach(cs => {
        totalScore += cs.actualScore;
        totalIdeal += cs.requiredScore;
    });


    const getIcon = (score, targetScore) => {
        if (score / targetScore < 0.7) {
            return <X />;
        }
        if (score / targetScore >= 0.8) {
            return <Check />;
        }
        return <Minus />;
    }

    return (
        <div>
            <div className={styles.matchModalHeader}>
                <div className={cx(styles.matchModalHeaderOverall, styles[getModifier(candidate.externalAssessment.jobScore.overallMatchingScore, 100)])}>
                    <p className={styles.matchModalHeaderPerc}>{candidate.externalAssessment.finishedAt ? Math.round(candidate.externalAssessment.jobScore.overallMatchingScore) : '-'}%</p>
                    {candidate.externalAssessment.finishedAt && <p>{totalScore}/{totalIdeal} Points</p>}
                </div>

                <div>
                    <h2 className='u-text-primary'>{candidate.name}</h2>
                    <p>Access code: <span className="bold">{candidate.externalAssessment.accessCode}</span></p>
                    <p>Complete: {moment(candidate.externalAssessment.finishedAt).format("lll") || '-'}</p>
                </div>
                <hr className='separator' />
            </div>
            <div className={styles.mobileScoreInfo}>
                {
                    candidate.externalAssessment.finishedAt && matchingScore.details.map(cs => {
                        const { actualScore, id, maximumScore, requiredScore, skillset } = cs;
                        return (
                            <div key={`cs_${id}`} className={cx(styles.otherMatches, styles.candidateItemScoreOverall)}>
                                <div className={styles[getModifier(actualScore, requiredScore)]}>{getIcon(actualScore, requiredScore)}</div>

                                <p className={styles.mobileActualScore}>{actualScore}</p>
                                <div className={styles.mobileScoreSkillset}>
                                    <p>{skillset}</p>
                                    <p>Ideal {requiredScore} | Max {maximumScore}</p>
                                </div>
                            </div>
                        )
                    })
                }
                {otherMatchingScores.length > 0 && <h4 className={styles.mobileOtherMatchesTitle}>Other Matched Roles</h4>}
            </div>

            {
                otherMatchingScores.map(ms => {
                    return (
                        <div className={cx(styles.otherMatches, styles.candidateItemScoreOverall)} key={`ms-${ms.id}`}>
                            <div className={styles[getModifier(ms.overallMatchingScore, 100)]}>{getIcon(ms.overallMatchingScore, 100)}</div>
                            <p className={styles.otherMatchesScore}>{Math.round(ms.overallMatchingScore)}%</p>
                            <p>{ms.jobName}</p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CandidateMatchModal;
