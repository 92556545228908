import React, { useState, useContext, useEffect } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Modal from '../../components/Modal'
import AddBtn from '../../components/AddBtn'
import GroupItem from './components/GroupItem';
import { GET_GROUPS } from './services/graphql';
import { AuthContext } from '../../services/user/AuthProvider';
import EditGroup from './components/EditGroup';
import CreateGroup from './components/CreateGroup';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';

const TABS = {
    active: 1,
    closed: 2,
    all: 3
}

const Groups = () => {

    const { currentUser } = useContext(AuthContext);
    const enterpriseId = currentUser.enterprise.id;

    const [showAddGroup, setShowAddGroup] = useState(false)
    const [showEditGroup, setShowEditGroup] = useState(false)
    const [selectedTab, setSelectedTab] = useState(TABS.active);

    const [baseGroups, setBaseGroups] = useState([]);

    const [getGroups, { data, error, loading }] = useLazyQuery(GET_GROUPS, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setBaseGroups(data.groups);
        }
    });

    useEffect(() => {
        if (data && data.groups !== baseGroups) {
            setBaseGroups(data.groups);
        }
    }, [data]);

    useEffect(() => {
        getGroups({
            variables: {
                enterpriseId,
                ...(selectedTab !== TABS.all && { active: selectedTab === TABS.active })
            }
        });
    }, [selectedTab, getGroups]);

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>Loading...</p>
    }

    if (data) {
        return (
            <>
                <div className='pageHeader'>
                    <div className='pageHeaderMain'>
                        <Tabs className='u-m-base-3'>
                            <Tab onClick={() => setSelectedTab(TABS.active)} selected={selectedTab === TABS.active}>Active</Tab>
                            <Tab onClick={() => setSelectedTab(TABS.closed)} selected={selectedTab === TABS.closed}>Closed</Tab>
                            <Tab onClick={() => setSelectedTab(TABS.all)} selected={selectedTab === TABS.all}>All</Tab>
                        </Tabs>
                    </div>
                    <div className='pageHeaderSide'>
                        <AddBtn action={() => setShowAddGroup(true)} />
                    </div>
                </div>
                <ul>
                    {baseGroups && baseGroups.map(g => <GroupItem setShowEditGrop={setShowEditGroup} key={`group${g.id}`} group={g} />)}
                </ul>
                {showAddGroup &&
                    <Modal closeModal={() => setShowAddGroup(false)}>
                        <CreateGroup enterpriseId={enterpriseId} close={() => setShowAddGroup(false)} />
                    </Modal>
                }

                {showEditGroup &&
                    <Modal closeModal={() => setShowEditGroup(null)}>
                        <EditGroup enterpriseId={enterpriseId} group={showEditGroup} selectedTab={selectedTab} tabs={TABS} close={() => setShowEditGroup(null)} />
                    </Modal>
                }
            </>
        )
    }

    return null
}

export default Groups