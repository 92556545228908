import React, { Fragment, useEffect, useState } from 'react';
import { Folder, User, LogOut, Menu, X } from 'react-feather';
import { Link } from '@reach/router';
import cx from 'classnames'
import { useLocation } from 'react-use';
import styles from './Header.module.scss';
import LogoutButton from '../LogoutButton';
import logo from '../../images/ce-logo.png';
import userImg from '../../images/user.svg';

const Header = ({ user }) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: cx(styles.headerLink, styles.headerLinkActive) } : { className: styles.headerLink }
    }

    useEffect(() => {
        if (!menuOpen) {
            document.body.classList.remove('preventScroll');
        }
    }, [menuOpen]);

    useEffect(() => {
        setMenuOpen(false);
    }, [location, setMenuOpen])

    const toggleMenu = () => {
        if (!menuOpen) {
            setMenuOpen(true);
            setTimeout(() => document.body.classList.add('preventScroll'), 500)
        } else {
            setMenuOpen(false);
            setTimeout(() => document.body.classList.remove('preventScroll'), 500)
        }
    }

    return (
        <header className={styles.header}>
            <div className={styles.headerInner}>
                <img src={logo} alt='' className={styles.logo} />
                {
                    user && (
                        <Fragment>
                            <div className={cx(styles.headerNav, { [styles.headerNavVisible]: menuOpen })}>
                                <img src={logo} alt='' className={styles.logoMobile} />
                                <span className={styles.headerCloseNav} onClick={() => toggleMenu()}><X size="28" /></span>
                                <Link to="/" getProps={isActive}>
                                    <User />
                                    <p>Candidates</p>
                                </Link>
                                <Link getProps={isActive} to="/groups">
                                    <Folder />
                                    <p>Groups</p>
                                </Link>
                                <Link getProps={isActive} to="/admins">
                                    <User />
                                    <p>Admins</p>
                                </Link>

                                <div className={styles.headerSide}>
                                    <Link getProps={isActive} to="/profile" >
                                        <img className={styles.headerProfileImg} src={userImg} alt='' /> <span className={styles.headerMobileTitle}>Profile</span>
                                    </Link>
                                    <LogoutButton classes={styles.headerLogout}>
                                        <LogOut /> <span className={styles.headerMobileTitle}>Logout</span>
                                    </LogoutButton>
                                </div>

                            </div>
                            <div className={styles.headerToggle}>
                                <Menu onClick={() => toggleMenu()} />
                            </div>
                        </Fragment>
                    )
                }
            </div>
        </header>
    )
}

export default Header;
