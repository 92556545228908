import React from 'react';
import Header from '../../../components/Header';
import styles from '../../Home/Home.module.scss'


const LoggedOutRouter = ({children}) => {
    return (
        <>
            <Header />
            <div className={styles.appContainer}>
              {children}
            </div>
        </>
    )
}

export default LoggedOutRouter;
