
export const adminQuery = `
id
firstName
lastName
name
email
active
enterprise {
    id
}
`;


export const ASSESSMENT_STATE = {
    not_started: 0,
    in_progress: 1,
    completed: 2,
}


export const CORE_SKILLS = ['Windows', 'Outlook', 'Chrome'];