import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import { GET_ANSWERS } from '../../services/graphql'

import AnswersContent from './AnswersContent';
import { getPercScores } from '../../../../services/utils';
import { CORE_SKILLS } from '../../../../services/constants';
import styles from '../../Candidate.module.scss'

const AnswersQuery = ({ candidate }) => {

    const { data, error, loading } = useQuery(GET_ANSWERS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            assessmentId: candidate.assessment.id
        },
    });


    const getTime = (answers, showCoreTime) => {
        let filteredAnswers = [];
        if (showCoreTime) {
            filteredAnswers = answers.filter(answer => CORE_SKILLS.includes(answer.question.skillset));
        } else {
            filteredAnswers = answers.filter(answer => !CORE_SKILLS.includes(answer.question.skillset));
        }
        const seconds = filteredAnswers.reduce((acc, curr) => acc + curr.duration, 0);
        const timeInMins = Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);
        return timeInMins;
    }

    if (loading) return <div>Loading...</div>;

    if (error) return <p>Oops, something went wrong...</p>

    if (data && data.assessmentAnswers) {

        const { assessmentAnswers } = data;

        return (
            <>
                <div className={styles.assessmentBar}>
                    <div className={styles.assessmentBarCore}>
                        Core skills: {getPercScores(candidate.candidateScores.filter(score => CORE_SKILLS.includes(score.skillset)))}
                        <span className='u-m-left-3'>{getTime(assessmentAnswers, true)}min</span>
                    </div>

                    <div className={styles.assessmentBarOther}>
                        Other skills: {getPercScores(candidate.candidateScores.filter(score => !CORE_SKILLS.includes(score.skillset)))}
                        <span className='u-m-left-3'>{getTime(assessmentAnswers, false)}min</span>
                    </div>
                </div>

                <ul>
                    <AnswersContent assessmentAnswers={assessmentAnswers} candidate={candidate} />
                </ul>
            </>
        )
    }
    return null;
}

export default AnswersQuery;