// This is the root of our app
// Will contain the app client and auth provider and link to router

import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { getClient } from '../../services/client';
import { SkillsetProvider } from '../../services/user/SkillsetProvider';
import { AuthProvider } from '../../services/user/AuthProvider';
import RouterAuthQuery from './components/RouterAuthQuery';


const Home = () => {
    return (
        <ApolloProvider client={getClient()} >
            <AuthProvider>
                <SkillsetProvider>
                    <RouterAuthQuery />
                </SkillsetProvider>
            </AuthProvider>
        </ApolloProvider>
    )
}

export default Home;