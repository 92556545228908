import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { Router } from '@reach/router';
import { useEffectOnce } from 'react-use';

import { AuthContext } from '../../../services/user/AuthProvider';
import { LOGGED_IN } from '../services/graphql';
import Ssl from './Ssl';
import LoggedOutRouter from './LoggedOutRouter';
import LoggedInRouter from './LoggedInRouter';
import RouteCapture from './RouteCapture';

import Dashboard from '../../Dashboard';
import Groups from '../../Groups';
import Group from '../../Group';
import Admins from '../../Admins';
import Candidate from '../../Candidate';
import Profile from '../../Profile';

import Login from '../../Login';
import SetPassword from '../../Login/Scenes/SetPassword'
import ResetSent from '../../Login/Scenes/ResetSent';


const RouterAuthQuery = () => {
    const { data, error, loading } = useQuery(LOGGED_IN);
    const auth = useContext(AuthContext);
    const [ready, setReady] = useState(false);


    useEffect(() => {
        if (ls('cutting-edge-admin-token') && !auth.currentUser) {
            auth.setCurrentUser(JSON.parse(ls('cutting_edge_user')));
        }
    }, [auth]);

    useEffectOnce(() => {
        if (ls('cutting-edge-admin-token') && !auth.currentUser && ls('cutting_edge_user') && ls('cutting_edge_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('cutting_edge_user')));
        }
        setReady(true);
    });

    const renderContent = () => {
        if (error) {
            return <p>{error.message}</p>
        }

        if (loading) {
            return <p>Loading</p>
        }

        if (data && ready) {
            return (
                <Router>
                    <RouteCapture path='/'>
                        {
                            auth.currentUser ? (
                                <LoggedInRouter path='/' default>
                                    <Dashboard path='/' />
                                    <Groups path='/groups' />
                                    <Admins path='/admins' />
                                    <Group path='/group/:groupId' />
                                    <Candidate path='/candidate/:candidateId' />
                                    <Profile path='/profile' />
                                </LoggedInRouter>
                            ) : (
                                <LoggedOutRouter path='/' default>
                                    <Login path='/' />
                                    <SetPassword path="/activate/:token" />
                                    <SetPassword path="/reset/:token/*" />
                                    <ResetSent path='/reset_sent' />
                                </LoggedOutRouter>
                            )
                        }
                    </RouteCapture >
                </Router>
            )
        }
    }

    return (
        <Fragment>
            <Ssl />
            {renderContent()}
        </Fragment>
    );
}

export default RouterAuthQuery;