import React, { useContext } from 'react';
import Header from '../../../components/Header';
import { AuthContext } from '../../../services/user/AuthProvider';
import { useQuery } from '@apollo/react-hooks';

import { CURRENT_USER_QUERY } from '../services/graphql';
import styles from '../../Home/Home.module.scss'
import SkillsetQuery from './SkillsetQuery';

const LoggedInRouter = ({ children }) => {
    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const { error, loading } = useQuery(CURRENT_USER_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ currentUser }) => {
            setCurrentUser(currentUser);
        },
        pollInterval: 60000
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>Loading</p>
    }

    if (currentUser.id) {
        return (
            <>
                <SkillsetQuery />
                <Header user={currentUser} />
                <div className={styles.appContainer}>
                   {children}
                </div>
            </>
        )
    }
}

export default LoggedInRouter;
